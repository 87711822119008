import {
   Dialog,
   DialogTitle,
   DialogPanel,
   Transition,
   TransitionChild,
} from '@headlessui/react';
import { Fragment } from 'react';

interface Props {
   title: string;
   children: React.ReactNode;
   onClose: () => void;
   secondaryButton?: React.ReactNode;
}
export default function Modal({
   title,
   children,
   onClose,
   secondaryButton,
}: Props) {
   return (
      <>
         <div className="fixed inset-0 flex items-center justify-center"></div>

         <Transition appear show={true} as={Fragment}>
            <Dialog
               as="div"
               className="fixed inset-0 z-10 overflow-y-auto"
               onClose={onClose}
            >
               <div className="min-h-screen px-4 text-center">
                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                     className="inline-block h-screen mt-40"
                     aria-hidden="true"
                  >
                     &#8203;
                  </span>
                  <TransitionChild
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 scale-95"
                     enterTo="opacity-100 scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 scale-100"
                     leaveTo="opacity-0 scale-95"
                  >
                     <DialogPanel className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                        <DialogTitle
                           as="h3"
                           className="text-lg font-medium leading-6 text-gray-900"
                        >
                           {title}
                        </DialogTitle>
                        <div className="mt-2">{children}</div>

                        <div className="mt-4">
                           <button
                              type="button"
                              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                              onClick={onClose}
                           >
                              Done
                           </button>
                           {secondaryButton}
                        </div>
                     </DialogPanel>
                  </TransitionChild>
               </div>
            </Dialog>
         </Transition>
      </>
   );
}
