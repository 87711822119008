import { useState } from 'react';

interface Props {
   placeholder: string;
   onChange: (value: string) => void;
   value?: string;
}

export default function DatePlaceholder({
   placeholder,
   onChange,
   value,
}: Props) {
   const [inputType, setInputType] = useState<'text' | 'date'>('text');

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
   };

   const inputValue = inputType === 'date' ? value : placeholder;

   return (
      <input
         onChange={handleChange}
         value={inputValue}
         className="form-input"
         type={inputType}
         onFocus={() => setInputType('date')}
         onBlur={() => setInputType('text')}
      />
   );
}
