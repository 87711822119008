import { Transition } from '@headlessui/react';
import { useRef, useState } from 'react';

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import {
   Calendar,
   DayValue,
} from '@hassanmojab/react-modern-calendar-datepicker';

import useOnClickOutside from '../hooks/useOnClickOutside';

interface Props {
   title: string;
   selectedValue?: DayValue;
   onChange: (value: any) => void;
}

function DateDropdown({ title, selectedValue, onChange }: Props) {
   const ref = useRef(null);

   const [open, setOpen] = useState(false);

   const [selectedDay, setSelectedDay] = useState<DayValue>(null);

   useOnClickOutside(ref, () => setOpen(open => !open));

   const handleClick = () => {
      setOpen(ref.current === null);
   };

   const handleClear = () => {
      onChange(null);
      setOpen(false);
   };

   const handleDateChange = (date: DayValue) => {
      onChange(date);
      setSelectedDay(date);
   };

   const displayValue = selectedValue
      ? `${title}: ${selectedValue.day}/${selectedValue.month}/${selectedValue.year}`
      : title;

   return (
      <div className="relative">
         <span className="rounded-md shadow-sm">
            <button
               onClick={handleClick}
               className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
            >
               <span>{displayValue}</span>
               <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
               >
                  <path
                     fillRule="evenodd"
                     d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                     clipRule="evenodd"
                  />
               </svg>
            </button>
         </span>

         <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
         >
            <div
               className="z-10 absolute left-0 w-56 mt-2 origin-top-right 
                   divide-y divide-gray-100   outline-none p-4"
               ref={ref}
            >
               <Calendar
                  value={selectedDay}
                  onChange={handleDateChange}
                  renderFooter={() => (
                     <div className=" absolute bottom-1 right-4 flex justify-end w-full ">
                        <button
                           onClick={handleClear}
                           className="border border-gray-300 rounded m-4 text-lg  text-black w-1/2 "
                        >
                           Reset Date
                        </button>
                     </div>
                  )}
               />
            </div>
         </Transition>
      </div>
   );
}

export default DateDropdown;
