import { FundManagerBatchGet } from 'types/interface';
import { formatDateString } from '../../lib/date';
import Table from '../Table';
import TableData from '../Table/TableData';
import TableHeader from '../Table/TableHeader';
import TableRow from '../Table/TableRow';
import TableRowHeader from '../Table/TableRowHeader';
import { formatReportingPeriod } from './batch';
interface Props {
   batches: FundManagerBatchGet[];
   onBatchClick: (batchId: number) => void;
   displayFundColumn?: boolean;
}

type BatchStatus = 'Approved' | 'Rejected' | 'Requires Approval' | undefined;

function getBatchStatus(batch: FundManagerBatchGet): BatchStatus {
   let status: BatchStatus;
   if (batch.requiresApproval) {
      if (batch.approvedAt) {
         status = 'Approved';
      } else if (batch.rejectedAt) {
         status = 'Rejected';
      } else {
         status = 'Requires Approval';
      }
   }
   return status;
}

export default function BatchesList({
   batches,
   onBatchClick,
   displayFundColumn,
}: Props) {
   return (
      <div>
         <Table>
            <TableHeader>
               <TableRowHeader>Name</TableRowHeader>
               {displayFundColumn && <TableRowHeader>Fund</TableRowHeader>}
               <TableRowHeader>Category</TableRowHeader>
               <TableRowHeader>Reporting Period</TableRowHeader>
               <TableRowHeader>Upload Date</TableRowHeader>
            </TableHeader>
            <tbody>
               {batches.map(batch => (
                  <TableRow
                     onClick={() => onBatchClick(batch.id)}
                     key={batch.id}
                     displayHoverEffect
                  >
                     <TableData>
                        <StatusBadge status={getBatchStatus(batch)} />{' '}
                        {batch.name}
                     </TableData>
                     {displayFundColumn && (
                        <TableData>{batch.fundName}</TableData>
                     )}
                     <TableData>{batch.category?.name}</TableData>
                     <TableData>
                        {formatReportingPeriod(
                           batch.reportingPeriodFormat,
                           batch.reportingPeriod,
                        )}
                     </TableData>
                     <TableData>{formatDateString(batch.createdAt)} </TableData>
                  </TableRow>
               ))}
            </tbody>
         </Table>
      </div>
   );
}

function StatusBadge({ status }: { status: BatchStatus }) {
   switch (status) {
      case 'Requires Approval':
         return <Badge className="bg-blue-100 text-blue-800">{status}</Badge>;
      case 'Approved':
         return <Badge className="bg-gray-200 text-gray-600">{status} </Badge>;
      case 'Rejected':
         return <Badge className="bg-red-100 text-red-800">{status}</Badge>;
      default:
         return null;
   }
}

function Badge({
   children,
   className,
}: {
   children: React.ReactNode;
   className?: string;
}) {
   return (
      <span
         className={`text-xs px-2 py-0.5 mr-2 rounded font-medium tracking-wide inline ${
            className || ''
         }`}
      >
         {children}
      </span>
   );
}
