import type { Location, LocationDescriptorObject } from 'history';
import { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import {
   UserContext,
   isAdminToken,
   isFundManager,
} from '../contexts/UserContext';

interface Props extends RouteProps {
   authLevel: 'admin' | 'super-admin' | 'user' | 'fundManager';
}

export interface RedirectLocationState {
   from: Location;
}

export default function ProtectedRoute({ authLevel, ...routeProps }: Props) {
   const { user } = useContext(UserContext);

   const userAuthLevel = isAdminToken(user)
      ? user.accessLevel
      : Boolean(user?.userId)
        ? 'user'
        : null;

   const isAuth =
      authLevel === userAuthLevel ||
      (authLevel === 'admin' && userAuthLevel === 'super-admin') ||
      (authLevel === 'fundManager' && isFundManager(user));

   // Not authorised - figure out where to send them
   const { location, path, exact, strict } = routeProps;

   const urlParams = new URLSearchParams(location?.search);

   const firstTimeToken = urlParams.get('t');
   const displayEmail = urlParams.get('u');

   if (isAuth) {
      return <Route {...routeProps} />;
   }

   // Find where to send them
   let redirect: LocationDescriptorObject<RedirectLocationState>;
   //admins accessing user routes should be rerouted to the admin page
   if (userAuthLevel) {
      if (['admin', 'super-admin'].includes(userAuthLevel))
         redirect = { pathname: '/admin' };
      else redirect = { pathname: '/forbidden' };
   }
   // Not authenticated at all
   else if (firstTimeToken) {
      redirect = {
         pathname: '/reset-password',
         search: `?token=${firstTimeToken}&first-time=1`,
      };
      // Admin page - go to admin login
   } else if (['admin', 'super-admin'].includes(authLevel)) {
      redirect = { pathname: '/admin/login' };
      // User page - go to user login
   } else {
      redirect = { pathname: '/login' };
      if (displayEmail) {
         redirect.search = `?u=${encodeURIComponent(displayEmail)}`;
      }
   }

   // Add 'from'
   redirect = { ...redirect, state: { from: location! } };

   return (
      <Redirect
         from={
            Array.isArray(path) ? path[0] : path
         } /* For some reason Route accepts an array and Redirect doesn't */
         exact={exact}
         strict={strict}
         to={redirect}
      />
   );
}
