import { SetStateAction, useEffect, useState } from 'react';
import { mfaApiLogin } from '../../../lib/api';

interface Props {
   mfaAuthId: number;
   sendOtcEmail: () => void;
   onSuccess: () => void;
   MethodIdentifier?: string;
   error: string;
   setError: (value: SetStateAction<string>) => void;
}

export default function MfaEmailForm({
   mfaAuthId,
   sendOtcEmail,
   onSuccess,
   MethodIdentifier,
   error,
   setError,
}: Props) {
   const [codeToVerify, setCodeToVerify] = useState('');

   useEffect(() => {
      sendOtcEmail();
   }, []);

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCodeToVerify(e.target.value);
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!codeToVerify) {
         return setError('Please enter code');
      }

      mfaApiLogin('post', 'mfa/email/verify', {
         mfaAuthId: mfaAuthId,
         code: codeToVerify,
      })
         .then(() => onSuccess())
         .catch(err =>
            setError(err.message ?? `The code did not match our records.`),
         );
   };

   return (
      <>
         <h2 className="mb-1 text-left text-3xl font-extrabold text-gray-900">
            Enter verification code
         </h2>
         {error ? (
            <div className="bg-red-200 p-4 rounded-md text-left my-2">
               {error}
            </div>
         ) : null}
         <h3 className="mb-4 font-regular text-gray-600">
            <p>We sent a code to your email address {MethodIdentifier}.</p>
         </h3>

         <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
               <label
                  htmlFor="verification-code"
                  className="block text-sm font-medium text-gray-700"
               >
                  Verification Code
               </label>
               <div className="mt-1">
                  <input
                     value={codeToVerify}
                     onChange={handleChange}
                     id="phone"
                     name="phone"
                     type="text"
                     required
                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
               </div>
            </div>
            <div>
               <button
                  type="submit"
                  disabled={codeToVerify.length !== 6}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-300"
               >
                  Verify
               </button>
               <button
                  type="button"
                  className="link my-4 block text-sm text-blue-400"
                  onClick={() => sendOtcEmail()}
               >
                  Resend email
               </button>
            </div>
         </form>
      </>
   );
}
