import { useEffect, useRef, useState } from 'react';
import Loader from '../../../../../components/Loader';

interface Props {
   onSubmit: (files: FileList | File[]) => void;
   isUploading: boolean;
   duplicateFileNames?: string[];
   initFiles?: { folder: File[]; fileList: FileList };
}

export default function AddFilesModal({
   onSubmit,
   isUploading,
   duplicateFileNames,
   initFiles,
}: Props) {
   const fileInput = useRef<HTMLInputElement>(null);
   const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
   const [fileItems, setFileItems] = useState<File[]>();

   useEffect(() => {
      if (initFiles) {
         if (initFiles.folder.length) {
            setFileItems(initFiles.folder);
         } else {
            setSelectedFiles(initFiles.fileList);
         }
         if (fileInput.current) {
            fileInput.current.files = initFiles.fileList;
         }
      }
   }, [initFiles]);

   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
         setSelectedFiles(e.target.files);
      }
   };

   const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (selectedFiles) {
         onSubmit(selectedFiles);
      }
      if (fileItems) {
         onSubmit(fileItems);
      }
   };

   return (
      <div className="text-left">
         {duplicateFileNames && (
            <div className="bg-red-300 p-2">
               <p>
                  The following files have the same filename as an exisiting
                  file:
               </p>
               <p>{duplicateFileNames.join(',')}</p>
               <p>
                  {' '}
                  Change the file names you are uploading to avoid naming
                  conflicts, or delete the existing file.
               </p>
            </div>
         )}

         <form onSubmit={handleSubmit}>
            <label>
               Files
               <input
                  ref={fileInput}
                  className="form-input"
                  type="file"
                  multiple
                  onChange={handleFileChange}
               />
            </label>
            {isUploading ? (
               <Loader />
            ) : (
               <button className="basic-btn mt-4">Submit</button>
            )}
         </form>
      </div>
   );
}
