import React, { useRef, useState } from 'react';
import { getFilesAsync } from '../lib/file';

interface Props {
   onUpload: (files: FileList, extensionType: string) => void;
   children: React.ReactNode;
   acceptableFormats?: string[];
   message?: string;
   onDragOver?: (e: any) => void;
   acceptFolder?: boolean;
   onFolderUpload?: (folder: File[], fileList: FileList) => void;
   disabled?: boolean;
}

function FileDragAndDrop({
   onUpload,
   children,
   acceptableFormats,
   message = 'Upload a file',
   onDragOver,
   acceptFolder,
   onFolderUpload,
   disabled,
}: Props) {
   const drag = useRef<EventTarget>();

   const [dragging, setDragging] = useState(false);

   const handleDragOver = (e: React.DragEvent) => {
      e.preventDefault();
      onDragOver?.(e);
   };

   const handleDrop = async (e: React.DragEvent) => {
      const { files, items } = e.dataTransfer;

      const item = items[0];

      const file = files[0];
      const isFolderUpload = item.kind === 'file' && item.type === '';

      if (!file.size && !isFolderUpload) {
         alert(
            'This file has no data. If it is in a zip file, extract it before uploading.',
         );
         setDragging(false);
         return;
      }

      const extensionType = file.name.split('.').pop() || '';

      if (isFolderUpload && acceptFolder) {
         e.preventDefault();
         const folder: File[] = await getFilesAsync(e.dataTransfer);
         onFolderUpload?.(folder, files);
      } else if (
         !acceptableFormats ||
         acceptableFormats?.includes(extensionType.toLowerCase())
      ) {
         e.preventDefault();
         onUpload(files, extensionType);
      }
      setDragging(false);
   };

   const handleDragEnter = (e: React.DragEvent) => {
      setDragging(true);
      drag.current = e.target;
   };

   const handleDragLeave = (e: React.DragEvent) => {
      if (drag.current === e.target) {
         setDragging(false);
      }
   };

   return (
      <>
         {disabled ? (
            <>{children}</>
         ) : (
            <div
               onDrop={handleDrop}
               onDragLeave={handleDragLeave}
               onDragEnter={handleDragEnter}
               onDragOver={handleDragOver}
            >
               {dragging && (
                  <div className="w-full h-full left-0 top-0 fixed bg-gray-800 z-10 opacity-70 flex items-center justify-center">
                     <div className="text-6xl text-white w-8/12 h-full border-4 border-dotted p-20">
                        {message}
                     </div>
                  </div>
               )}
               {children}
            </div>
         )}
      </>
   );
}

export default FileDragAndDrop;
