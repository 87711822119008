import DocumentViewer from '../components/DocumentViewer';
import DocumentCard from '../components/DocumentCard';
import { DocumentGet, FundAccountsGet, DocTypeGet, Fund } from 'types';

import Pagination from '../components/Pagination';
import useGetNonTypeSafe from '../hooks/useGetNonTypeSafe';

import ListDropdown from '../components/ListDropdown';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

import DateDropdown from '../components/DateDropdown';

import { Day } from '@hassanmojab/react-modern-calendar-datepicker';
import { api, remove } from '../lib/api';
import DocumentTable from '../components/DocumentTable';
import Banner from './Banner';
import useGet from '../hooks/useGet';
import { DocumentBaseUrl } from './DocumentsWrapper';

const DOCUMENT_STATUS_OPTIONS = [
   { label: 'Documents', value: 'published' },
   { label: 'Draft Documents', value: 'draft' },
   { label: 'Depublished Documents', value: 'depublished' },
];

interface ListDropDownOption {
   label: string;
   value: any;
}

interface AccountFund {
   value: number;
   label: string;
   fund: Fund;
}

interface AccountFundFilters {
   funds: ListDropDownOption[];
   accounts: AccountFund[];
}

export interface DocumentFilters {
   fund?: string;
   account?: string;
   docType?: string;
   fromDate?: Day;
   toDate?: Day;
   bulk?: string;
   documentStatus?: string;
   order?: 'ASC' | 'DESC';
   sortBy?: string;
   documentName?: string;
}

type DocFilter =
   | 'fund'
   | 'account'
   | 'docType'
   | 'fromDate'
   | 'toDate'
   | 'bulk'
   | 'documentStatus'
   | 'documentName';

interface DocumentInterface extends Omit<DocumentGet, 'isPublished'> {}

interface DocumentViewerProps {
   url: string;
   filename: string;
   date: string;
   documentType: DocTypeGet;
}

interface DocumentsAndCount {
   documents: DocumentInterface[];
   count: number;
}

interface BulkForm {
   publishedAt: null | Date;
   emailNotificationAt: null | Date;
   emailAttachment: boolean;
}

const ADMIN_PAGINAGTION_OPTIONS = [10, 25, 50, 100, 300, 500, 1000];
const INVESTOR_PAGINATION_OPTIONS = [10, 25, 50];

const formatAccountFundFilters = (data: FundAccountsGet[]) => {
   const filters: AccountFundFilters = { funds: [], accounts: [] };

   data.forEach(fund => {
      filters.funds.push({ value: fund.id, label: fund.name });
      fund.accounts.forEach(account =>
         filters.accounts.push({
            value: account.id,
            label: account.name,
            fund,
         }),
      );
   });

   filters.funds.sort((a, b) =>
      a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
   );
   filters.accounts.sort((a, b) =>
      a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
   );
   return filters;
};

const formatDocTypeFilters = (data: DocTypeGet[]) =>
   data
      .map(docType => ({ label: docType.name, value: docType.id }))
      .concat([
         {
            label: 'Other',
            value: 0,
         },
      ]);

const bulkOptionLabel = (id: number, createdAt: string) => {
   const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
   };

   return `${id} - ${new Date(createdAt).toLocaleDateString(
      undefined,
      options,
   )}`;
};

const formatBulkFilters = (data: { id: number; createdAt: string }[]) =>
   data.map(bulkFilter => ({
      value: bulkFilter.id,
      label: bulkOptionLabel(bulkFilter.id, bulkFilter.createdAt),
   }));

const RESULT_LIMIT = 10;
const DEFAULT_FILTERS: DocumentFilters = {
   documentStatus: 'published',
   order: 'DESC',
   sortBy: 'publishedAt',
   documentName: '',
};

export const formatDate = (d: Day) => {
   if (!d) {
      return undefined;
   }
   const { day, month, year } = d;
   const dd = day < 10 ? `0${day}` : day;
   const mm = month < 10 ? `0${month}` : month;

   return `${year}-${mm}-${dd}`;
};

interface Props {
   baseUrl: DocumentBaseUrl;
   adminPage?: boolean;
   fundId?: number;
   accountId?: number;
   accountFiltersUrl?: string | null;
   fundPage?: boolean;
   filters: DocumentFilters;
   setFilters: (filters: DocumentFilters) => void;
   limit: number;
   setLimit: (limit: number) => void;
   pageNum: number;
   setPageNum: (pageNum: number) => void;
   onSendDocumentToUser?: (documentId: number) => void;
}

function Documents({
   baseUrl,
   adminPage,
   fundId,
   accountId,
   accountFiltersUrl = null,
   fundPage,
   filters,
   limit,
   setLimit,
   setFilters,
   pageNum,
   setPageNum,
   onSendDocumentToUser,
}: Props) {
   const [bulkUnpublishMessage, setBulkUnpublishMessage] = useState('');
   const [bulkUnpublishErrorMessage, setBulkUnpublishErrorMessage] =
      useState('');

   const [bulkResendMessage, setBulkResendMessage] = useState('');
   const [bulkResendErrorMessage, setBulkResendErrorMessage] = useState('');

   const [bulkPublishMessage, setBulkPublishMessage] = useState('');
   const [bulkPublishErrorMessage, setBulkPublishErrorMessage] = useState('');

   const [selectedIds, setSelectedIds] = useState<number[]>([]);

   const handleFilterClick = (value: any, filter: DocFilter) => {
      let f = { ...filters };

      if (filter === 'docType' && value === 0) {
         f[filter] = value;
      } else if (!value) {
         delete f[filter];
      } else {
         f[filter] = value;
      }

      //unset account filter selection when fund is selected
      if (filter === 'fund') {
         delete f.account;
      }

      setFilters(f);
   };

   const handleSortClick = (name: string) => {
      let f = { ...filters };
      const order = f.sortBy === name && f.order === 'DESC' ? 'ASC' : 'DESC';
      f.order = order;
      f.sortBy = name;
      setFilters(f);
   };

   const searchParams = Object.entries(filters).map<[string, string]>(
      ([key, value]) => {
         switch (key) {
            case 'fromDate':
            case 'toDate':
               return [key, formatDate(value)];
            case 'docType':
               const formattedType = value > 0 ? value : null;
               return [key, formattedType];
            default:
               return [key, value];
         }
      },
   );

   //don't trigger the search more than once every 250ms
   const [debouncedSearchParams] = useDebounce(searchParams, 250);

   const [documentQueryResult, errors, loading, refresh] = useGet(
      `${baseUrl}?pageNum=${pageNum}&limit=${limit}&${new URLSearchParams(
         debouncedSearchParams,
      )}`,
   );

   const bulkUnpublishDocuments = (selectedIds: number[]) => {
      setBulkUnpublishMessage('');
      setBulkUnpublishErrorMessage('');

      const confirm = window.confirm(
         'Are you sure you would like to depublish these documents?',
      );
      if (!confirm) {
         return;
      }

      remove(`document/bulk-depublish`, { documentIds: selectedIds })
         .then(res => {
            setBulkUnpublishMessage('Depublished selected documents.');
            //de-select the ids afterwards
            setSelectedIds([]);
            refresh();
         })
         .catch(err =>
            setBulkUnpublishErrorMessage(
               'Could not depublish selected documents.',
            ),
         );
   };

   const bulkResendDocuments = (selectedIds: number[]) => {
      if (
         !confirm(
            `Are you sure you want to resend ${selectedIds.length} documents?`,
         )
      ) {
         return;
      }

      setBulkResendMessage('');
      setBulkResendErrorMessage('');

      const emailNotificationAt = new Date(new Date().getTime() + 30 * 60000);

      api.patch(`document/bulk-edit?ids=${selectedIds.join(',')}`, {
         emailNotificationSent: false,
         emailNotificationAt,
      })
         .then(res => {
            setBulkResendMessage(
               'Resent notification emails for selected documents.',
            );
            refresh();
         })
         .catch(err =>
            setBulkResendErrorMessage(
               'Could not resend notification emails for selected documents.',
            ),
         );
   };

   const bulkPublishDocuments = (
      selectedIds: number[],
      bulkFormData: BulkForm,
   ) => {
      setBulkPublishMessage('');
      setBulkPublishErrorMessage('');

      api.patch(`document/bulk-edit?ids=${selectedIds.join(',')}`, bulkFormData)
         .then(res => {
            setBulkPublishMessage('Published selected documents.');
            refresh();
         })
         .catch(err =>
            setBulkPublishErrorMessage('Could not publish selected documents.'),
         );
   };

   const [accountFundFilters, accountFundFilterErrors] = useGetNonTypeSafe<
      FundAccountsGet[],
      AccountFundFilters
   >(accountFiltersUrl, formatAccountFundFilters);

   const docTypeFiltersUrl = adminPage ? 'document/types' : 'me/doc-types';
   const [docTypeFilters, docTypeFilterErrors] = useGetNonTypeSafe<
      DocTypeGet[],
      ListDropDownOption[]
   >(docTypeFiltersUrl, formatDocTypeFilters);

   const bulkFiltersUrl = adminPage && fundId ? `fund/${fundId}/bulk` : null;

   const [bulkFilters, bulkFiltersError] = useGetNonTypeSafe<
      { id: number; createdAt: string }[],
      ListDropDownOption[]
   >(bulkFiltersUrl, formatBulkFilters);

   const [selectedDocument, setSelectedDocument] =
      useState<null | DocumentViewerProps>();

   //only display accounts from selected fund
   const filteredAccountsByFund =
      (filters.fund
         ? accountFundFilters?.accounts.filter(
              account => account.fund.id === Number(filters.fund),
           )
         : accountFundFilters?.accounts) || [];
   const accountOptions = [
      { label: 'All Accounts', value: '' },
      ...filteredAccountsByFund,
   ];

   //display fund based on account selected
   const selectedFund =
      Number(filters.fund) > 0
         ? Number(filters.fund)
         : accountFundFilters?.accounts.find(
              a => a.value === Number(filters.account),
           )?.fund.id;

   return (
      <>
         {bulkUnpublishMessage && (
            <Banner type="success"> {bulkUnpublishMessage} </Banner>
         )}
         {bulkUnpublishErrorMessage && (
            <Banner type="error"> {bulkUnpublishErrorMessage} </Banner>
         )}
         {bulkResendMessage && (
            <Banner type="success"> {bulkResendMessage} </Banner>
         )}
         {bulkResendErrorMessage && (
            <Banner type="error"> {bulkResendErrorMessage} </Banner>
         )}
         {bulkPublishMessage && (
            <Banner type="success"> {bulkPublishMessage} </Banner>
         )}
         {bulkPublishErrorMessage && (
            <Banner type="error"> {bulkPublishErrorMessage} </Banner>
         )}
         {selectedDocument ? (
            <DocumentViewer
               {...selectedDocument}
               onBack={() => setSelectedDocument(null)}
            />
         ) : (
            <>
               <div
                  className={adminPage ? 'min-h-screen' : 'mx-4 py-20 md:py-24'}
               >
                  <div className="max-w-5xl mx-auto">
                     <div className="mb-3 pb-1 md:pb-5 ">
                        <h1 className="mb-5 text-3xl text-left font-bold leading-tight text-gray-900">
                           Documents
                        </h1>
                        {/* Filters Section */}

                        <div className="flex flex-wrap gap-2">
                           <DateDropdown
                              title="From Date"
                              onChange={value =>
                                 handleFilterClick(value, 'fromDate')
                              }
                              selectedValue={filters.fromDate}
                           />

                           <DateDropdown
                              title="To Date"
                              onChange={value =>
                                 handleFilterClick(value, 'toDate')
                              }
                              selectedValue={filters.toDate}
                           />

                           {Boolean(accountFundFilters) ? (
                              <>
                                 <ListDropdown
                                    title="Fund Name"
                                    options={[
                                       { label: 'All Funds', value: '' },
                                       ...(accountFundFilters?.funds || []),
                                    ]}
                                    onChange={value =>
                                       handleFilterClick(value, 'fund')
                                    }
                                    selectedValue={selectedFund}
                                 />

                                 <ListDropdown
                                    title="Account Name"
                                    options={accountOptions}
                                    onChange={value =>
                                       handleFilterClick(value, 'account')
                                    }
                                    selectedValue={Number(filters.account)}
                                 />
                              </>
                           ) : null}

                           {adminPage && fundId && !accountId && (
                              <ListDropdown
                                 title="Batch Ids"
                                 options={[
                                    { label: 'All Batch Ids', value: '' },
                                    ...(bulkFilters || []),
                                 ]}
                                 onChange={value =>
                                    handleFilterClick(value, 'bulk')
                                 }
                                 selectedValue={Number(filters.bulk)}
                              />
                           )}

                           <ListDropdown
                              title="Doc Type"
                              options={[
                                 { label: 'All Doc Types', value: '' },
                                 ...(docTypeFilters || []),
                              ]}
                              onChange={value =>
                                 handleFilterClick(value, 'docType')
                              }
                              selectedValue={Number(filters.docType)}
                           />

                           {adminPage && (fundId || accountId) && (
                              <ListDropdown
                                 title="Doc Status"
                                 options={DOCUMENT_STATUS_OPTIONS}
                                 onChange={value =>
                                    handleFilterClick(value, 'documentStatus')
                                 }
                                 selectedValue={filters.documentStatus}
                              />
                           )}

                           <div>
                              <input
                                 type="text"
                                 placeholder="Document Name"
                                 value={filters.documentName ?? ''}
                                 onChange={e =>
                                    handleFilterClick(
                                       e.target.value,
                                       'documentName',
                                    )
                                 }
                                 className="inline-flex items-center px-6 py-2.5 border 
                               border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                              />
                           </div>

                           <div>
                              <button
                                 onClick={() => setFilters(DEFAULT_FILTERS)}
                                 className="inline-flex items-center px-6 py-2.5 border 
          border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                              >
                                 Clear Filters
                              </button>
                           </div>
                        </div>
                     </div>

                     {loading ? (
                        <div className="text-center">Loading ...</div>
                     ) : (
                        <>
                           <div className="hidden sm:block">
                              {documentQueryResult?.documents && (
                                 <DocumentTable
                                    bulkUnpublishDocuments={
                                       bulkUnpublishDocuments
                                    }
                                    fundPage={fundPage}
                                    bulkResendDocuments={bulkResendDocuments}
                                    refresh={refresh}
                                    adminPage={adminPage}
                                    documents={documentQueryResult.documents}
                                    setSelectedDocument={setSelectedDocument}
                                    bulkPublishDocuments={bulkPublishDocuments}
                                    canBulkPublish={
                                       filters.documentStatus === 'draft'
                                    }
                                    onSortClick={handleSortClick}
                                    sortOrder={filters.order || null}
                                    sortBy={filters.sortBy}
                                    selectedIds={selectedIds}
                                    setSelectedIds={setSelectedIds}
                                    onSendDocumentToUser={onSendDocumentToUser}
                                 />
                              )}
                           </div>

                           <div className="block sm:hidden">
                              {documentQueryResult?.documents?.map(doc => (
                                 <DocumentCard
                                    refresh={refresh}
                                    document={doc}
                                    key={doc.id}
                                    adminPage={adminPage}
                                    setSelectedDocument={setSelectedDocument}
                                    onSendDocumentToUser={onSendDocumentToUser}
                                 />
                              ))}
                           </div>
                        </>
                     )}
                  </div>
               </div>

               {/* Pagination */}
               <Pagination
                  className="mt-4"
                  count={documentQueryResult?.count ?? 0}
                  resultLength={documentQueryResult?.documents?.length || 1}
                  pageNum={pageNum}
                  setPageNum={setPageNum}
                  setLimit={setLimit}
                  limit={limit}
                  itemType="documents"
                  paginationOptions={
                     adminPage
                        ? ADMIN_PAGINAGTION_OPTIONS
                        : INVESTOR_PAGINATION_OPTIONS
                  }
               />
            </>
         )}
      </>
   );
}

export default Documents;
