interface FormModalProps {
   children: JSX.Element;
   title: string;
   display?: boolean;
   onClose: () => void;
}

function FormModal({
   children,
   title,
   display = true,
   onClose,
}: FormModalProps) {
   return (
      <>
         {display ? (
            <>
               <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative  w-full my-6 mx-auto max-w-3xl md:w-screen">
                     {/*content*/}
                     <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none  max-h-screen overflow-y-auto ">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                           <h3 className="text-3xl font-semibold">{title}</h3>
                           <button
                              className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                              onClick={onClose}
                           >
                              <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                                 ×
                              </span>
                           </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">{children}</div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b"></div>
                     </div>
                  </div>
               </div>
               <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
         ) : null}
      </>
   );
}

export default FormModal;
