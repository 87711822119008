interface Props {
   children: React.ReactNode;
}

function LoggedOutLayout({ children }: Props) {
   return (
      <div className="min-h-screen flex flex-col justify-center bg-no-repeat bg-center bg-cover bg-building">
         <img
            className="mx-auto h-24 md:h-32 xl:h-36"
            src="/img/apex-logo-white.svg"
            alt="Workflow"
         />
         <div className="mt-8 mx-auto w-full max-w-md">
            <div className="bg-white p-8 shadow rounded-lg mx-8 md:mx-auto">
               {children}
            </div>
         </div>
      </div>
   );
}

export default LoggedOutLayout;
