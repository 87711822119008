import { formatDateString } from '../../lib/date';

interface Props {
   approvedBy?: string;
   approvedAt?: Date | null;
   rejectedAt?: Date | null;
   requiresApproval: boolean;
}

export default function BatchStatusMessage({
   approvedAt,
   approvedBy,
   rejectedAt,
   requiresApproval,
}: Props) {
   if (requiresApproval) {
      const className =
         'text-center text-lg border-white text-white shadow-md p-6 mb-6';
      if (approvedAt && approvedBy) {
         return (
            <div className={`bg-green-500 ${className}`}>
               These documents were approved by{' '}
               <span className="font-bold">{approvedBy}</span> at{' '}
               {formatDateString(approvedAt, true)}
            </div>
         );
      } else if (rejectedAt) {
         return (
            <div className={`bg-red-400 ${className}`}>
               These documents were rejected at{' '}
               {formatDateString(rejectedAt, true)}
            </div>
         );
      } else {
         return (
            <div className={`bg-blue-400 ${className}`}>
               These documents require approval.
            </div>
         );
      }
   }

   return null;
}
