import { DocTypeGet } from 'types';
import usePdf from '../hooks/usePdf';
import DocumentTypeBadge from './DocumentTypeBadge';

interface Props {
   url: string;
   filename: string;
   date: string;
   documentType?: DocTypeGet;
   onBack: () => void;
}

function DocumentViewer({ url, ...props }: Props) {
   const options = {
      pageContainerClass:
         'w-10/12 mx-auto shadow-md border-b border-gray-300 mb-4 ',
      pageCanvasClass: 'w-full',
   };

   const pdfRef = usePdf(url, options);

   return (
      <>
         <DocumentViewerNav url={url} {...props} />
         <div className="pt-20"></div>
         <div ref={pdfRef} />
      </>
   );
}

function DocumentViewerNav({
   url,
   filename,
   date,
   documentType,
   onBack,
}: Props) {
   return (
      <nav className="bg-white shadow-sm fixed top-0 w-full z-50">
         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16 items-center">
               {/* Back Button - Close Button */}
               <button
                  type="button"
                  onClick={onBack}
                  className=" inline-flex items-center px-3 py-2 mr-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
               >
                  {/* Heroicon name: solid/mail   class="-ml-0.5 mr-2 h-4 w-4" */}
                  <svg
                     className="-ml-0.5 mr-2 h-4 w-4"
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                  >
                     <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                     />
                  </svg>
                  Back
               </button>

               <div className="flex mx-2 items-center sm:grow sm:flex-row">
                  {/* File Name */}
                  <div className="max-w-full text-lg font-normal text-gray-800 grow text-ellipsis whitespace-nowrap overflow-hidden sm:max-w">
                     {filename}
                  </div>

                  {/* Date */}
                  <div className="hidden sm:inline-flex px-6 py-4 text-left whitespace-nowrap text-base text-gray-500">
                     {date}
                  </div>

                  {/* <!-- Doc Type --> */}
                  {documentType && (
                     <div className="hidden sm:inline-flex">
                        <DocumentTypeBadge docType={documentType} />
                     </div>
                  )}
               </div>

               {/* Doc View Header Right Content */}
               <div className="flex items-center ">
                  {/* Download Button */}
                  <a
                     className="ml-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                     href={url}
                  >
                     Download
                  </a>
               </div>
            </div>
         </div>
      </nav>
   );
}

export default DocumentViewer;
