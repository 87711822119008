import LoggedOutLayout from '../components/LoggedOutLayout';

function NotFound() {
   return (
      <LoggedOutLayout>
         <h2 className="text-4xl">404</h2>
         <p className="mt-4">
            Sorry, this page does not exist. Make sure you are logged into the
            correct account.
         </p>
         <p className="mt-4">
            <a href="/" className="link">
               Return to homepage
            </a>
         </p>
      </LoggedOutLayout>
   );
}

export default NotFound;
