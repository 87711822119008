import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Banner from '../../components/Banner';
import BatchRoutes from '../../components/Batches/BatchRoutes';
import useGet from '../../hooks/useGet';
import { patch } from '../../lib/api';

export default function Batch({
   match,
}: RouteComponentProps<{ batchId: string }>) {
   const { batchId } = match.params;

   const [batch, batchError, loading, refresh] = useGet(
      `files/batches/${Number(batchId)}`,
   );

   const [batchApprovedMessage, setBatchApprovedMessage] = useState('');
   const [batchErrorMessage, setBatchErrorMessage] = useState('');

   const baseUrl = 'files/batches';

   const handleApproveBatch = async () => {
      const confirmation = confirm(
         batch?.approvalConfirmationMessage ||
            'Are you sure you want to approve these documents?',
      );
      if (!confirmation) {
         return;
      }
      setBatchErrorMessage('');
      try {
         await patch(`files/batches/${Number(batchId)}`, {
            approve: true,
         });
         setBatchApprovedMessage('Batch approved.');
         refresh();
      } catch (err) {
         setBatchErrorMessage(err.message);
      }
   };

   const handleRejectBatch = async () => {
      const rejectionMessage = prompt(
         'What is the reason for rejecting these documents?',
      );
      if (rejectionMessage === null) {
         return;
      }

      setBatchErrorMessage('');
      try {
         await patch(`files/batches/${Number(batchId)}`, {
            reject: true,
            rejectMessage: rejectionMessage,
         });
         setBatchApprovedMessage('Batch rejected.');
         refresh();
      } catch (err) {
         setBatchErrorMessage(err.message);
      }
   };

   return (
      <>
         {batchApprovedMessage && (
            <Banner type="success"> {batchApprovedMessage}</Banner>
         )}
         {batchErrorMessage && (
            <Banner type="error"> {batchErrorMessage}</Banner>
         )}
         {batch && (
            <BatchRoutes
               batch={batch}
               onApproveBatch={handleApproveBatch}
               onRejectBatch={handleRejectBatch}
               basePath={match.url}
               baseUrl={baseUrl}
            />
         )}
      </>
   );
}
