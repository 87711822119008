interface Props {
   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
   value?: string;
   checked?: boolean;
   label?: string;
   className?: string;
}
export default function Toggle({
   onChange,
   value,
   checked = false,
   label,
   className,
}: Props) {
   return (
      <label
         className={`inline-flex items-center cursor-pointer ${className || ''}`}
      >
         <input
            type="checkbox"
            value={value}
            onChange={onChange}
            className="sr-only peer"
            checked={checked}
         />
         <div className="relative w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
         <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            {label}
         </span>
      </label>
   );
}
