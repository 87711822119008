import Select, { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { Account } from 'types';
import { ReactSelectOption } from '../../../types';

interface Props {
   accounts: Account[];
   selectedAccountIds: number[];
   onChange: (accountIds: number[]) => void;
}

export default function AccountReqest({
   accounts,
   selectedAccountIds,
   onChange,
}: Props) {
   const options: ReactSelectOption[] = accounts.map(account => ({
      value: account.id,
      label: account.name,
   }));

   const handleChange = (
      newValue: MultiValue<ReactSelectOption>,
      actionMeta: ActionMeta<ReactSelectOption>,
   ) => {
      const values = newValue.map(value => value.value);
      onChange(values);
   };

   const selectedValues = selectedAccountIds.reduce(
      (total: ReactSelectOption[], current: number) => {
         const account = accounts.find(account => account.id === current);
         if (account) {
            total.push({ value: account.id, label: account.name });
         }
         return total;
      },
      [],
   );

   return (
      <>
         <label className="form-label">Select Account</label>

         <Select
            isMulti={true}
            options={options}
            value={selectedValues}
            isClearable
            onChange={handleChange}
         />
      </>
   );
}
