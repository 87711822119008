import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
   .use(Backend)
   .use(initReactI18next)
   .init({
      supportedLngs: ['en', 'he'],
      lng: 'en',
      fallbackLng: false, // Use the key
      returnEmptyString: false,
      debug: process.env.NODE_ENV !== 'production',
      interpolation: { escapeValue: false }, // not needed for react as it escapes by default
   });

export default i18n;
