interface Props {
   onChange: (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
   ) => void;
   emailAddress: string;
}

export default function BulkInvestorRequest({ onChange, emailAddress }: Props) {
   return (
      <>
         <label
            htmlFor="email_address"
            className="mt-4 mb-2 block text-sm font-medium text-gray-700 text-left"
         >
            Investor's email addresses
         </label>
         <textarea
            required
            onChange={onChange}
            value={emailAddress}
            name="userEmail"
            id="email_address"
            autoComplete="email"
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
         />
      </>
   );
}
