import { PropsWithChildren } from 'react';
import AdminNavbar from './Navbar/AdminNavbar';

const AdminLayout: React.FC<React.PropsWithChildren<PropsWithChildren<{}>>> = ({
   children,
}) => {
   return (
      <div className="text-center min-h-screen flex flex-col">
         <AdminNavbar />
         <div className="pt-20"></div>
         {children}
         <footer className="mt-auto">
            <div className="bg-gray-100 text-gray-400 mt-12 p-8">
               <i>
                  For portal support please email{' '}
                  <a
                     href={'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL}
                     className="text-gray-500"
                  >
                     {process.env.REACT_APP_SUPPORT_EMAIL}
                  </a>
               </i>
            </div>
         </footer>
      </div>
   );
};

export default AdminLayout;
