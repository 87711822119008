import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import ReportingConsole from './AppInsightsConsole';

const browserHistory = createBrowserHistory({ basename: '' });
const connectionString = process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING;
let appInsights: ApplicationInsights | null = null;
if (connectionString) {
   const reactPlugin = new ReactPlugin();
   appInsights = new ApplicationInsights({
      config: {
         connectionString,
         disableFetchTracking: false,
         extensions: [reactPlugin],
         extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
         },
      },
   });
   appInsights.loadAppInsights();
   window.console = new ReportingConsole(appInsights);
}

// import './i18n';

createRoot(document.getElementById('root')!).render(
   <React.StrictMode>
      <App history={browserHistory} />
   </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (appInsights) {
   reportWebVitals(({ name, value }) =>
      appInsights.trackMetric({ name, average: value }),
   );
}
