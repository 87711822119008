import { Link } from 'react-router-dom';

interface Props {
   href: string;
   children: React.ReactNode;
   className?: string;
}

export default function NavItem({ href, children, className = '' }: Props) {
   return (
      <Link to={href} className={className}>
         {children}
      </Link>
   );
}
