import { useEffect, useState } from 'react';
import useGetNonTypeSafe from './useGetNonTypeSafe';

export default function useModifiableGet<T>(
   path: string | null,
): [T | undefined, string | undefined, boolean, (data: T) => void] {
   const [modifiedValue, setModifiedValue] = useState<T>();
   useEffect(() => setModifiedValue(undefined), [path]);
   const [value, error, loading] = useGetNonTypeSafe<T>(path);
   return [modifiedValue ?? value, error, loading, setModifiedValue];
}
