import { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation, useParams } from 'react-router-dom';
import { DocumentGet } from 'types';
import { DocumentViewerProps } from '../components/DocumentCard';
import DocumentViewer from '../components/DocumentViewer';
import useGetNonTypeSafe from '../hooks/useGetNonTypeSafe';
import { api } from '../lib/api';
import { formatDateString } from '../lib/date';
import NotFound from './NotFound';

interface LocationState extends Omit<DocumentViewerProps, 'url'> {}

function Document({ history, match }: RouteComponentProps<{ id: string }>) {
   const { state } = useLocation<LocationState>();
   const { id } = match.params;
   const [documentError, setDocumentError] = useState('');
   const [selectedDocument, setSelectedDocument] =
      useState<null | DocumentViewerProps>();

   const getDocumentFromApi = async () => {
      return api.get(`document/${id}/download`);
   };

   useEffect(() => {
      const viewDocument = async () => {
         try {
            const url = (await getDocumentFromApi()) as string;
            if (state?.filename) {
               setSelectedDocument({ url, ...state });
            } else {
               //get document data when investor is coming from a direct link (email)
               const document = await api.get<DocumentGet>(
                  `me/documents/${id}`,
               );
               const { fileName, documentDate, documentType } = document;
               const docDate = formatDateString(documentDate);
               setSelectedDocument({
                  url,
                  filename: fileName,
                  date: docDate,
                  documentType,
               });
            }
         } catch (err) {
            setDocumentError(err.message);
         }
      };
      viewDocument();
   }, [state]);

   const handleBack = () => {
      if (state) {
         history.goBack();
      } else {
         history.push('/');
      }
   };

   if (['Not Found', 'Forbidden'].includes(documentError)) {
      return <NotFound />;
   }

   return selectedDocument ? (
      <DocumentViewer {...selectedDocument} onBack={handleBack} />
   ) : (
      <div>Loading...</div>
   );
}

export default Document;
