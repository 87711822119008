import { useEffect, useState } from 'react';
import { MfaMethod } from 'types';
import { mfaApiCall, mfaApiLogin } from '../../../lib/api';
import { MfaSetupMethodProps } from '../MfaSetupSingleMethod';
import MfaEmailForm from './MfaEmailForm';

export default function MfaSetupEmail({ onSuccess }: MfaSetupMethodProps) {
   const [error, setError] = useState('');
   const [methodInfo, setMethodInfo] = useState<MfaMethod>();

   useEffect(() => {
      sendOtcEmail();
   }, []);

   const sendOtcEmail = () => {
      mfaApiCall<MfaMethod>('get', `mfa/setup/email`)
         .then(setMethodInfo)
         .catch(err => setError(err.message));
   };

   return (
      methodInfo && (
         <MfaEmailForm
            mfaAuthId={methodInfo.id}
            sendOtcEmail={sendOtcEmail}
            onSuccess={onSuccess}
            MethodIdentifier={methodInfo.identifier}
            error={error}
            setError={setError}
         />
      )
   );
}
