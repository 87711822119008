import {
   NavLink,
   Redirect,
   Route,
   Switch,
   useHistory,
   useRouteMatch,
} from 'react-router-dom';
interface Tab {
   label: string;
   path: string;
   content: React.ReactNode;
   hideTab?: boolean;
}

interface TabsProps {
   tabs: Tab[];
}

function Tabs({ tabs }: TabsProps) {
   const match = useRouteMatch();

   return (
      <div className="bg-transparent mb-6">
         <nav className="flex flex-row justify-center">
            {tabs.map(
               tab =>
                  !tab.hideTab && (
                     <NavLink
                        activeClassName="py-4 mx-4 block font-medium hover:text-blue-500 focus:outline-none
                  text-blue-500 border-b-2  border-blue-500"
                        key={tab.label}
                        to={tab.path}
                        className="py-4 mx-4 block font-medium hover:text-blue-500 focus:outline-none
                  text-gray-600"
                     >
                        {tab.label}
                     </NavLink>
                  ),
            )}
         </nav>

         <Switch>
            {tabs.map(
               tab =>
                  !tab.hideTab && (
                     <Route path={`${match.path}/${tab.path}`} key={tab.path}>
                        {tab.content}
                     </Route>
                  ),
            )}
            <Redirect to={`${match.path}/${tabs[0].path}`} />
         </Switch>
      </div>
   );
}

export default Tabs;
