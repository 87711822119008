export function downloadBlob(blob: Blob, fileName: string) {
   const blobUrl = URL.createObjectURL(blob);

   // Create a link element
   const link = document.createElement('a');

   // Set link's href to point to the Blob URL
   link.href = blobUrl;
   link.download = fileName;
   link.target = '_blank';

   // Append link to the body
   document.body.appendChild(link);

   // Dispatch click event on the link
   // This is necessary as link.click() does not work on the latest firefox
   link.dispatchEvent(
      new MouseEvent('click', {
         bubbles: true,
         cancelable: true,
         view: window,
      }),
   );

   // Remove link from body
   document.body.removeChild(link);
}

export function downloadDocumentFromPath(path: string, fileName = '') {
   // Create a link element
   const link = document.createElement('a');

   // Set link's href to point to the Blob URL
   link.href = path;
   link.download = fileName;

   // Append link to the body
   document.body.appendChild(link);

   // Dispatch click event on the link
   // This is necessary as link.click() does not work on the latest firefox
   link.dispatchEvent(
      new MouseEvent('click', {
         bubbles: true,
         cancelable: true,
         view: window,
      }),
   );

   // Remove link from body
   document.body.removeChild(link);
}

export function uploadFileToAzure(file: File, url: string) {
   return fetch(url, {
      method: 'PUT',
      headers: {
         'Content-Type': file.type,
         'x-ms-blob-content-type': file.type,
         'x-ms-blob-type': 'BlockBlob',
      },
      body: file,
   });
}

export function readFileAsBase64(file: File): Promise<string> {
   return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
         let base64result = '';
         if (typeof reader.result === 'string') {
            base64result = reader.result.split(',')[1];
         }

         resolve(base64result);
      };

      reader.readAsDataURL(file);
      reader.onerror = error => reject(error);
   });
}

/*code taken from: https://www.meziantou.net/upload-files-and-directories-using-an-input-drag-and-drop-or-copy-and-paste-with.htm */
export async function getFilesAsync(dataTransfer: DataTransfer) {
   const files: File[] = [];

   await Promise.all(
      Object.entries(dataTransfer.items).map(async ([, item]) => {
         if (item.kind === 'file') {
            if (typeof item.webkitGetAsEntry === 'function') {
               const entry = item.webkitGetAsEntry();
               if (entry) {
                  const entryContent = await readEntryContentAsync(entry);
                  files.push(...entryContent);
               }
            } else {
               const file = item.getAsFile();
               if (file) {
                  files.push(file);
               }
            }
         }
      }),
   );

   return files;
}

// Returns a promise with all the files of the directory hierarchy
function readEntryContentAsync(entry: FileSystemEntry) {
   return new Promise<File[]>((resolve, reject) => {
      let reading = 0;
      const contents: File[] = [];

      readEntry(entry);

      function readEntry(entry: FileSystemEntry) {
         if (isFile(entry)) {
            reading++;
            const path = entry.fullPath.substring(1);
            entry.file(file => {
               reading--;
               const fullNameFile = new File([file], path);
               contents.push(fullNameFile);

               if (reading === 0) {
                  resolve(contents);
               }
            });
         } else if (isDirectory(entry)) {
            readReaderContent(entry.createReader());
         }
      }

      function readReaderContent(reader: FileSystemDirectoryReader) {
         reading++;

         reader.readEntries(function (entries) {
            reading--;
            for (const entry of entries) {
               readEntry(entry);
            }

            if (reading === 0) {
               resolve(contents);
            }
         });
      }
   });
}

// for TypeScript typing (type guard function)
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
function isDirectory(
   entry: FileSystemEntry,
): entry is FileSystemDirectoryEntry {
   return entry.isDirectory;
}

function isFile(entry: FileSystemEntry): entry is FileSystemFileEntry {
   return entry.isFile;
}
