import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';

interface Props {
   children: React.ReactNode;
   type: 'error' | 'success';
}

export default function Banner({ type, children }: Props) {
   const [display, setDisplay] = useState(true);

   useEffect(() => {
      setTimeout(() => {
         setDisplay(false);
      }, 2000);
   }, []);

   return (
      <>
         <Transition
            show={display}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
         >
            <div className="absolute min-h-screen min-w-full left-0 z-10">
               {type === 'error' ? (
                  <ErrorBanner>{children}</ErrorBanner>
               ) : (
                  <SuccessBanner>{children}</SuccessBanner>
               )}
            </div>
         </Transition>
      </>
   );
}

function SuccessBanner({ children }: { children: React.ReactNode }) {
   return (
      <div
         className="relative top-10 z-10
      space-x-2 bg-emerald-50 p-4 rounded flex items-center text-emerald-600 my-4 shadow-lg mx-auto max-w-2xl w-full"
      >
         <div>
            <svg
               xmlns="http://www.w3.org/2000/svg"
               className="h-6 w-6"
               viewBox="0 0 20 20"
               fill="currentColor"
            >
               <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
               />
            </svg>
         </div>
         <h3 className="text-emerald-800 tracking-wider flex-1">{children}</h3>
      </div>
   );
}

function ErrorBanner({ children }: { children: React.ReactNode }) {
   return (
      <div
         className="relative top-10 z-10
      space-x-2 bg-red-50 p-4 rounded flex items-center text-red-600 my-4 shadow-lg mx-auto max-w-2xl w-full"
      >
         <div>
            <svg
               xmlns="http://www.w3.org/2000/svg"
               className="h-6 w-6"
               viewBox="0 0 20 20"
               fill="currentColor"
            >
               <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
               />
            </svg>
         </div>
         <h3 className="text-red-800 tracking-wider flex-1">{children}</h3>
      </div>
   );
}
