interface Props {
   children: React.ReactNode;
}
export default function TableHeader({ children }: Props) {
   return (
      <thead>
         <tr className="text-left border-b-2 border-gray-300">{children}</tr>
      </thead>
   );
}
