import { Menu, Transition } from '@headlessui/react';
import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext, isFundManager } from '../../contexts/UserContext';
import useSignout from '../../hooks/useSignout';

function classNames(...classes: string[]) {
   return classes.filter(Boolean).join(' ');
}

export default function PopoverMenu() {
   const signout = useSignout();
   const { user } = useContext(UserContext);

   return (
      <Menu as="div" className="ml-3 relative">
         {({ open }) => (
            <>
               <div>
                  <Menu.Button className="bg-gray-300 flex text-sm rounded-full focus:outline-none ">
                     <span className="sr-only">Open user menu</span>
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-7 w-7 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                     >
                        <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth={2}
                           d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                     </svg>
                  </Menu.Button>
               </div>
               <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
               >
                  <Menu.Items
                     static
                     className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                     <Menu.Item>
                        {({ active }) => (
                           <Link
                              to="/profile"
                              className={classNames(
                                 active ? 'bg-gray-100' : '',
                                 'block px-4 py-2 text-sm text-gray-700',
                              )}
                           >
                              My Profile
                           </Link>
                        )}
                     </Menu.Item>

                     <Menu.Item>
                        {({ active }) => (
                           <Link
                              to="/"
                              className={classNames(
                                 active ? 'bg-gray-100' : '',
                                 'block sm:hidden px-4 py-2 text-sm text-gray-700',
                              )}
                           >
                              Documents
                           </Link>
                        )}
                     </Menu.Item>

                     {/*Fund manager requests*/}
                     {isFundManager(user) && (
                        <Menu.Item>
                           {({ active }) => (
                              <Link
                                 to="/requests"
                                 className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block sm:hidden px-4 py-2 text-sm text-gray-700',
                                 )}
                              >
                                 Requests
                              </Link>
                           )}
                        </Menu.Item>
                     )}

                     <Menu.Item>
                        {({ active }) => (
                           <Link
                              to="/forms"
                              className={classNames(
                                 active ? 'bg-gray-100' : '',
                                 'block sm:hidden px-4 py-2 text-sm text-gray-700',
                              )}
                           >
                              Forms
                           </Link>
                        )}
                     </Menu.Item>

                     <Menu.Item>
                        {({ active }) => (
                           <Link
                              to="/contact"
                              className={classNames(
                                 active ? 'bg-gray-100' : '',
                                 'block sm:hidden px-4 py-2 text-sm text-gray-700',
                              )}
                           >
                              Contact Us
                           </Link>
                        )}
                     </Menu.Item>

                     <Menu.Item>
                        {({ active }) => (
                           <button
                              onClick={signout}
                              className={classNames(
                                 active ? 'bg-gray-100' : '',
                                 'text-left block w-full px-4 py-2 text-sm text-gray-700',
                              )}
                           >
                              Sign out
                           </button>
                        )}
                     </Menu.Item>
                  </Menu.Items>
               </Transition>
            </>
         )}
      </Menu>
   );
}
