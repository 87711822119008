import { useEffect, useState } from 'react';

import { api } from '../lib/api';

function useGetNonTypeSafe<T, DFT = T>(
   path: string | null,
   dataFormatter?: (data: T) => DFT,
): [DFT | undefined, string | undefined, boolean, () => void] {
   const [value, setValue] = useState<DFT>();
   const [errorMessage, setErrorMessage] = useState<string>();
   const [loading, setLoading] = useState(false);

   const [counter, setCounter] = useState(0);

   useEffect(() => {
      if (path === null) return;
      let cancelled = false;

      const getDataFromApi = async () => {
         try {
            setLoading(true);
            let res: T = await api.get<T>(path);
            const formatted: DFT = dataFormatter
               ? dataFormatter(res)
               : (res as unknown as DFT);

            if (!cancelled) {
               setValue(formatted);
               setLoading(false);
            }
         } catch (err) {
            if (!cancelled) {
               err instanceof Error
                  ? setErrorMessage(err.message)
                  : setErrorMessage(err);
               setLoading(false);
            }
         }
      };

      getDataFromApi();
      return () => {
         cancelled = true;
         setLoading(false);
      };
   }, [path, counter, dataFormatter]);

   const refresh = () => setCounter(counter => counter + 1);

   return [value, errorMessage, loading, refresh];
}

export default useGetNonTypeSafe;
