import UserNavbar from '../components/Navbar/UserNavbar';
import DocumentsWrapper from '../components/DocumentsWrapper';

function InvestorDocuments() {
   return (
      <div className="min-h-screen bg-gray-100">
         <UserNavbar />
         <DocumentsWrapper
            baseUrl={'me/documents'}
            accountFiltersUrl="me/accounts"
         />
      </div>
   );
}

export default InvestorDocuments;
