import {
   anonymousPasskeyLogin,
   get as apiGet,
   post as apiPost,
   mfaApiLogin,
} from './api';
import {
   parseCreationOptionsFromJSON,
   create,
   get,
   parseRequestOptionsFromJSON,
} from '@github/webauthn-json/browser-ponyfill';

/*
3 passkey scenarios:
- Create a new passkey (user already signed in)
- Login using passkey alone (no token)
- Verify login using passkey (user has a token)

Challenges coming from the server have a challenge ID so they can be matched
*/

export const passkeysSupported = !!window.PublicKeyCredential;

export async function createPasskey(discoverable: boolean = true) {
   try {
      // Get the user's info from the server, including a challenge
      const { request, challengeId } = await apiGet('mfa/passkey');

      // Construct the request
      // Add residentKey based on user preference
      request.publicKey!.authenticatorSelection = {
         ...request.publicKey!.authenticatorSelection,
         residentKey: discoverable ? 'preferred' : 'discouraged',
      };
      // Use the ponyfill until browsers support it natively
      const nativeRequest = parseCreationOptionsFromJSON(request);

      // Attempt create
      const credential = (await create(nativeRequest)).toJSON();

      // Send to the server
      return apiPost('mfa/passkey', { credential, challengeId });
   } catch (e) {
      console.error(e);
      throw e;
   }
}

/**
 * This should be paired with an input element with the correct autocomplete attribute
 */
export async function anonymousLogin(
   signal?: AbortSignal,
   interactive = false,
) {
   try {
      const { challenge, challengeId } = await apiGet('login/passkey');
      const requestOptions = parseRequestOptionsFromJSON({
         publicKey: { challenge },
         mediation: interactive ? 'optional' : 'conditional',
      });
      if (signal) {
         requestOptions.signal = signal;
      }
      const signature = (await get(requestOptions)).toJSON();

      return anonymousPasskeyLogin({ signature, challengeId });
   } catch (e) {
      console.error(e);
      throw e;
   }
}

/**
 * Perform MFA using a passkey for a user who has already logged in
 * @param signal
 */
export async function userLogin(signal?: AbortSignal) {
   try {
      const { challengeId, ...publicKey } = await apiGet(
         'mfa/passkey/authentication',
      );
      const requestOptions = parseRequestOptionsFromJSON({
         publicKey,
         mediation: 'required',
      });
      if (signal) {
         requestOptions.signal = signal;
      }

      const signature = (await get(requestOptions)).toJSON();

      return mfaApiLogin('POST', 'mfa/passkey/authentication', {
         signature,
         challengeId,
      });
   } catch (e) {
      console.error(e);
      throw e;
   }
}
