interface Props {
   children: React.ReactNode;
   displayHoverEffect?: boolean;
   onClick?: (e?: any) => unknown;
}

export default function TableRow({
   children,
   displayHoverEffect,
   onClick,
}: Props) {
   return (
      <tr
         className={`text-left bg-gray-100 border-b border-gray-200 ${
            displayHoverEffect ? 'hover:bg-gray-200 cursor-pointer' : ''
         }`}
         onClick={e => onClick && onClick(e)}
      >
         {children}
      </tr>
   );
}
