import React, { useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import type {
   ManagedFunds,
   OutputGet,
   RequestQuery,
   SubDocRequestWithOutputs,
   SubDocRequestPostSingle,
   SubDocRequestPostBulk,
   SubDocRequestResponseSingle,
   SubDocRequestResponseBulk,
   SubDocRequestQueryResult,
} from 'types';
import { useDebounce } from 'use-debounce';
import useGetNonTypeSafe from '../hooks/useGetNonTypeSafe';
import { api } from '../lib/api';
import { formatDateString } from '../lib/date';
import RequestForm from '../pages/Request/RequestForm';
import SendRequestModal from '../pages/Request/SendRequestModal';
import Banner from './Banner';
import DocumentSortIcon from './DocumentSortIcon';
import FormModal from './FormModal';
import ListDropdown from './ListDropdown';
import Pagination from './Pagination';

const STATUS_OPTIONS = [
   { label: 'Complete', value: 'Complete' },
   { label: 'In Progress', value: 'In Progress' },
   { label: 'New', value: 'New' },
   { label: 'All Statuses', value: '' },
];

interface FormOptions {
   label: string;
   value: number;
}

const DEFAULT_FILTERS: RequestQuery = {
   order: 'DESC',
   sortBy: 'createdAt',
   searchTerm: '',
};

interface Props {
   managedFundsUrl: string;
   requestsBaseUrl: string;
   baseFundUrl: string;
   fundPage?: boolean;
}

type SubDocRequestPost = SubDocRequestPostBulk | SubDocRequestPostSingle;
type SubDocRequestResponse =
   | SubDocRequestResponseSingle
   | SubDocRequestResponseBulk;

function isSingleSdResponse(
   sdResponse: SubDocRequestResponse,
): sdResponse is SubDocRequestResponseSingle {
   return 'id' in sdResponse;
}

export default function RequestsTable({
   managedFundsUrl,
   requestsBaseUrl,
   fundPage,
   baseFundUrl,
}: Props) {
   const history = useHistory();
   const match = useRouteMatch();
   const location = useLocation();
   const urlParams = new URLSearchParams(location.search);

   const [requestErrorMessage, setRequestErrorMessage] = useState('');
   const [requestToken, setRequestToken] = useState('');
   const [showModal, setShowModal] = useState(false);
   const [requestLoading, setRequestLoading] = useState(false);
   const [bulkEmailsSent, setBulkEmailsSent] = useState(false);

   const [selectedRequests, setSelectedRequests] = useState<
      SubDocRequestWithOutputs[]
   >([]);

   //filters
   const [funds, fundsError] =
      useGetNonTypeSafe<ManagedFunds[]>(managedFundsUrl);

   const fundsWithForms = funds?.filter(fund => fund.forms.length);

   const fundsFilter =
      fundsWithForms?.map(fund => ({ label: fund.name, value: fund.id })) ?? [];

   const formsFilter =
      fundsWithForms?.reduce((total: FormOptions[], current) => {
         current.forms.forEach(form => {
            //only add a form that hasn't been added yet
            if (!total.find(f => f.value === form.id)) {
               total.push({
                  label: form.name,
                  value: form.id,
               });
            }
         });
         return total;
      }, []) ?? [];

   const sendBulkRequest = async (
      fundId: number,
      data: SubDocRequestPostBulk,
   ) => {
      setRequestErrorMessage('');
      setRequestLoading(true);
      setBulkEmailsSent(false);

      try {
         const res = await api.post<SubDocRequestPostBulk, SubDocRequestPost>(
            `fund/${fundId}/request/bulk`,
            data,
         );
         setShowModal(false);
         setBulkEmailsSent(true);

         refresh();
      } catch (err) {
         setRequestErrorMessage(err.message);
      } finally {
         setRequestLoading(false);
      }
   };

   const sendSingleRequest = async (
      fundId: number,
      data: SubDocRequestPostSingle,
      openFormInNewTab = false,
   ) => {
      setRequestErrorMessage('');
      setRequestLoading(true);
      setBulkEmailsSent(false);

      try {
         const res = await api.post<SubDocRequestPostSingle, SubDocRequestPost>(
            `fund/${fundId}/request`,
            data,
         );
         setShowModal(false);

         if (isSingleSdResponse(res) && openFormInNewTab) {
            open(res.userUrl, '_blank');
         } else {
            if (isSingleSdResponse(res) && !res.emailNotification) {
               setRequestToken(res.userUrl);
            } else {
               setBulkEmailsSent(true);
            }
         }

         refresh();
      } catch (err) {
         setRequestErrorMessage(err.message);
      } finally {
         setRequestLoading(false);
      }
   };

   const formatStatus = (startedAt: Date | null, completedAt: Date | null) => {
      if (completedAt) {
         return 'Complete';
      }
      if (startedAt) {
         return 'In Progress';
      }
      return 'New';
   };

   const getRequestToken = async (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      requestId: number,
   ) => {
      e.stopPropagation();
      try {
         const res = await api.get<string>(
            `request/${requestId}/generate-link`,
         );
         setRequestToken(res);
      } catch (err) {
         setRequestErrorMessage(err.message);
      }
   };

   const handleFilterClick = (value: any, filter: keyof RequestQuery) => {
      if (!value) {
         urlParams.delete(filter);
      } else {
         urlParams.set(filter, value);
      }

      //reset form if fund is chosen
      if (filter === 'fundId') {
         urlParams.delete('formId');
      }

      history.push(`?${urlParams}`);
   };

   const handleClearFilters = () => {
      history.push(
         `?${new URLSearchParams({
            order: 'DESC',
            sortBy: 'createdAt',
         })}`,
      );
   };

   const handleSortClick = (name: string) => {
      const sortBy = urlParams.get('sortBy');
      const prevOrder = urlParams.get('order');
      const order = sortBy === name && prevOrder === 'DESC' ? 'ASC' : 'DESC';
      urlParams.set('order', order);
      urlParams.set('sortBy', name);
      history.push(`?${urlParams}`);
   };

   const handleCheckboxChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      request: SubDocRequestWithOutputs,
   ) => {
      const { checked } = e.target;
      const selected = checked
         ? [...selectedRequests, request]
         : selectedRequests.filter(r => r.id !== request.id);
      setSelectedRequests(selected);
   };

   //debounce the location search for querying. 250ms
   const [locationSearch] = useDebounce(location.search, 250);

   const [requestsQueryResult, requestsError, loading, refresh] =
      useGetNonTypeSafe<SubDocRequestQueryResult>(
         `${requestsBaseUrl}?${new URLSearchParams(locationSearch)}`,
      );

   const { requests, count } = requestsQueryResult ?? {
      requests: [],
      count: 0,
   };

   const handleCheckBoxChangeAll = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      const selected = checked ? requests : [];
      setSelectedRequests(selected);
   };

   //display forms based on selected fund
   const filteredForms =
      fundsWithForms
         ?.find(f => f.id === Number(urlParams.get('fundId')))
         ?.forms.map(f => ({ label: f.name, value: f.id })) ?? formsFilter;

   const bulkSelectedOutputs = selectedRequests.reduce(
      (outputs: OutputGet[], req, currentIndex) =>
         currentIndex === 0
            ? req.outputs
            : outputs.filter(o => req.outputs.some(ro => ro.id === o.id)),
      [],
   );

   const selectedRequestIds = selectedRequests.map(r => r.id);

   const handleBulkOutputDownload = (output: OutputGet) => {
      api.download(
         `request/bulk-download/output/${
            output.id
         }?ids=${selectedRequestIds.join(',')}`,
      );
   };

   const handleBulkJsonDownload = () => {
      api.download(
         `request/bulk-download/json?ids=${selectedRequestIds.join(',')}`,
      );
   };

   //ensure order is correct type
   const getOrder = (): 'ASC' | 'DESC' => {
      const order = urlParams.get('order') ?? '';
      switch (order) {
         case 'ASC':
         case 'DESC':
            return order;
         default:
            return 'DESC';
      }
   };

   //filters
   const order = getOrder();
   const sortBy = urlParams.get('sortBy') ?? 'createdAt';
   const searchTerm = urlParams.get('searchTerm') ?? '';
   const fundId = urlParams.get('fundId')
      ? Number(urlParams.get('fundId'))
      : '';
   const formId = urlParams.get('formId')
      ? Number(urlParams.get('formId'))
      : '';
   const status = urlParams.get('status') ?? '';
   const limit = urlParams.get('limit') ? Number(urlParams.get('limit')) : 20;
   const pageNum = urlParams.get('pageNum')
      ? Number(urlParams.get('pageNum'))
      : 1;
   const includeArchived = Boolean(urlParams.get('includeArchived'));

   return (
      <>
         {bulkEmailsSent && (
            <Banner type="success">Email notifications sent!</Banner>
         )}
         {requestToken && (
            <SendRequestModal
               requestToken={requestToken}
               onClose={() => setRequestToken('')}
            />
         )}

         <FormModal
            title="New Request"
            display={showModal}
            onClose={() => setShowModal(false)}
         >
            <RequestForm
               baseFundUrl={baseFundUrl}
               funds={fundsWithForms}
               onSingleSubmit={sendSingleRequest}
               onBulkSubmit={sendBulkRequest}
               errorMessage={requestErrorMessage}
               loading={requestLoading}
            />
         </FormModal>

         <div className="mx-4 py-20 md:py-24">
            <div className="max-w-5xl mx-auto">
               <div className="pb-1 md:pb-5 sm:flex md:items-center md:justify-between">
                  <h1 className="text-3xl font-bold leading-tight text-gray-900 text-left">
                     Requests
                  </h1>
                  <div className="mt-3 flex">
                     <button
                        onClick={() => setShowModal(true)}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                     >
                        New Request
                     </button>
                  </div>
               </div>

               {/* request filters */}
               <div className="flex flex-wrap gap-2">
                  <ListDropdown
                     title="Status"
                     options={STATUS_OPTIONS}
                     onChange={value => handleFilterClick(value, 'status')}
                     selectedValue={status}
                  />

                  {!fundPage && (
                     <ListDropdown
                        title="Fund Name"
                        options={[
                           { label: 'All Funds', value: '' },
                           ...fundsFilter,
                        ]}
                        onChange={value => handleFilterClick(value, 'fundId')}
                        selectedValue={fundId}
                     />
                  )}

                  <ListDropdown
                     title="Form Name"
                     options={[
                        { label: 'All Forms', value: '' },
                        ...filteredForms,
                     ]}
                     onChange={value => handleFilterClick(value, 'formId')}
                     selectedValue={formId}
                  />

                  <div>
                     <input
                        type="text"
                        placeholder="Investor Email or Name"
                        value={searchTerm}
                        onChange={e =>
                           handleFilterClick(e.target.value, 'searchTerm')
                        }
                        className="inline-flex items-center px-6 py-2.5 border 
                               border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                     />
                  </div>

                  <div>
                     <button
                        onClick={handleClearFilters}
                        className="inline-flex items-center px-6 py-2.5 border 
          border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                     >
                        Clear Filters
                     </button>
                  </div>
               </div>

               <div className="leading-4 font-medium rounded-md text-gray-700 mb-10 mt-4">
                  <input
                     type="checkbox"
                     className="focus:ring-blue-500 h-4 text-blue-600 border-gray-300 rounded mr-2"
                     checked={includeArchived}
                     onChange={e =>
                        handleFilterClick(e.target.checked, 'includeArchived')
                     }
                  />
                  Include Archived
               </div>

               <div className="mt-3 flex sm:mt-0 sm:ml-4"></div>
               {requests?.length ? (
                  <div className="max-w-screen-lg ">
                     <BulkDownloadOptions
                        jsonDownloadDisabled={selectedRequestIds.length < 1}
                        onJsonDownload={handleBulkJsonDownload}
                        outputs={bulkSelectedOutputs}
                        onDownload={handleBulkOutputDownload}
                     />
                     <table className="w-full  bg-gray-200 text-gray-800">
                        <thead>
                           <tr className="text-left border-b-2 border-gray-300">
                              <th className="px-4 py-3 ">
                                 <input
                                    className="focus:ring-blue-500 h-5 w-5 text-blue-600 border-gray-300 rounded"
                                    onChange={handleCheckBoxChangeAll}
                                    type="checkbox"
                                 />
                              </th>
                              <th className="px-4 py-3 ">Investor</th>
                              {/* <th className="px-4 py-3 ">Investor Name</th> */}
                              {!fundPage && <th className="px-4 py-3">Fund</th>}
                              <th className="px-4 py-3">Form</th>
                              <th
                                 className="px-4 py-3 cursor-pointer"
                                 onClick={() => handleSortClick('createdAt')}
                              >
                                 Sent Date
                                 {sortBy === 'createdAt' && (
                                    <DocumentSortIcon order={order} />
                                 )}
                              </th>
                              <th
                                 className="px-4 py-3 cursor-pointer"
                                 onClick={() => handleSortClick('completedAt')}
                              >
                                 Submission Date
                                 {sortBy === 'completedAt' && (
                                    <DocumentSortIcon order={order} />
                                 )}
                              </th>
                              <th className="px-4 py-3">Status</th>
                              <th className="px-4 py-3">Link</th>
                           </tr>
                        </thead>
                        <tbody>
                           {requests?.map(request => (
                              <tr
                                 onClick={() =>
                                    history.push(`${match.url}/${request.id}`)
                                 }
                                 key={request.id}
                                 className="text-left bg-gray-100 border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                              >
                                 <td className="px-4 py-3">
                                    <input
                                       className="focus:ring-blue-500 h-5 w-5 text-blue-600 border-gray-300 rounded"
                                       type="checkbox"
                                       onClick={e => {
                                          e.stopPropagation();
                                       }}
                                       onChange={e =>
                                          handleCheckboxChange(e, request)
                                       }
                                       checked={selectedRequestIds.includes(
                                          request.id,
                                       )}
                                    />
                                 </td>
                                 <td className="px-4 py-3">
                                    <div>
                                       {request.userEmail ??
                                          request.accountName}
                                    </div>
                                    <div>{request.secondUserEmail}</div>
                                    <div className="text-gray-600 text-sm">
                                       {request.investorName}
                                    </div>
                                 </td>

                                 {!fundPage && (
                                    <td className="px-4 py-3">
                                       {request.fundName}
                                    </td>
                                 )}

                                 <td className="px-4 py-3">
                                    {request.formName}
                                 </td>
                                 <td className="px-4 py-3">
                                    {formatDateString(request.createdAt)}
                                 </td>
                                 <td className="px-4 py-3">
                                    {formatDateString(request.completedAt)}
                                 </td>
                                 <td className="px-4 py-3">
                                    {formatStatus(
                                       request.startedAt,
                                       request.completedAt,
                                    )}
                                 </td>

                                 <td className="px-4 py-3">
                                    <button
                                       className="action-btn"
                                       onClick={e =>
                                          getRequestToken(e, request.id)
                                       }
                                    >
                                       Regenerate Link
                                    </button>
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                     </table>

                     {/* pagination */}
                     <Pagination
                        className="mt-4"
                        count={count}
                        resultLength={requests?.length || 1}
                        pageNum={pageNum}
                        setPageNum={value =>
                           handleFilterClick(String(value), 'pageNum')
                        }
                        setLimit={value =>
                           handleFilterClick(String(value), 'limit')
                        }
                        limit={limit}
                        itemType="requests"
                        paginationOptions={[20, 50, 100]}
                     />
                  </div>
               ) : (
                  <div className="bg-white overflow-hidden shadow rounded-lg">
                     <div className="px-4 py-5 sm:p-6">
                        {/* Requests */}
                        <div className="mt-0">
                           <p>
                              You currently have no requests. Click above to add
                              a new one.
                           </p>
                        </div>
                     </div>
                  </div>
               )}
            </div>
         </div>
      </>
   );
}

interface BulkDownloadOptionsProps {
   outputs: OutputGet[];
   onDownload: (output: OutputGet) => void;
   onJsonDownload: () => void;
   jsonDownloadDisabled: boolean;
}

function BulkDownloadOptions({
   outputs,
   onDownload,
   onJsonDownload,
   jsonDownloadDisabled,
}: BulkDownloadOptionsProps) {
   return (
      <div className="max-w-screen-lg rounded-t-lg bg-gray-200 text-gray-800">
         <div className="flex">
            <div className="flex items-center">
               <button
                  disabled={jsonDownloadDisabled}
                  className={`action-btn m-2 ${
                     jsonDownloadDisabled && 'opacity-50'
                  }`}
                  type="button"
                  onClick={onJsonDownload}
               >
                  {' '}
                  Bulk Download JSON
               </button>
            </div>
            {outputs.map(output => (
               <div key={output.id} className="flex items-center">
                  <button
                     className="action-btn m-2"
                     type="button"
                     onClick={() => onDownload(output)}
                  >
                     {' '}
                     Bulk Download {output.name}{' '}
                  </button>
               </div>
            ))}
         </div>
      </div>
   );
}
