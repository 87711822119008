import UserNavbar from './Navbar/UserNavbar';

interface Props {
   children: React.ReactNode;
}
export default function LoggedInLayout({ children }: Props) {
   return (
      <>
         <UserNavbar />
         {children}
      </>
   );
}
