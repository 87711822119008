import Modal from '../../components/Modal';
import CopyTextButton from '../../components/CopyTextButton';
interface Props {
   requestToken: string;
   onClose: () => void;
}
export default function SendRequestModal({ requestToken, onClose }: Props) {
   return (
      <Modal
         title="Request created"
         onClose={onClose}
         secondaryButton={<CopyTextButton text={requestToken} />}
      >
         <p className="break-words max-w-full">
            Send the following link to your investor:
            <a
               className="underline text-blue-500 block"
               href={requestToken}
               target="_blank"
            >
               {requestToken}
            </a>
         </p>
      </Modal>
   );
}
