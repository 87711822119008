import { Link, useHistory } from 'react-router-dom';
import TableData from '../../components/Table/TableData';
import TableRow from '../../components/Table/TableRow';

interface Props {
   id: number;
   batchId: number;
   name: string;
   onDownload: () => void;
   onClick: () => void;
   onDelete?: () => void;
}

export default function FileRow({
   name,
   onDownload,
   onClick,
   onDelete,
}: Props) {
   const handleRowClick: React.MouseEventHandler<HTMLTableRowElement> = e => {
      if (
         !(
            e.target instanceof HTMLInputElement ||
            e.target instanceof HTMLButtonElement ||
            e.target instanceof HTMLAnchorElement
         )
      ) {
         onClick();
      }
   };

   return (
      <TableRow onClick={handleRowClick} displayHoverEffect>
         <TableData>{name}</TableData>
         <TableData>
            <div className="flex">
               <button className="action-btn" onClick={onDownload}>
                  Download
               </button>
               {onDelete && (
                  <button className="delete-btn-sm ml-2" onClick={onDelete}>
                     Delete
                  </button>
               )}
            </div>
         </TableData>
      </TableRow>
   );
}
