interface Props {
   batchName: string;
   fundName: string;
}

export default function BatchHeader({ batchName, fundName }: Props) {
   return (
      <>
         <div className="pb-1 md:pb-2 sm:flex md:items-center md:justify-between">
            <h1 className="text-3xl font-bold leading-tight text-gray-900 text-left">
               Files from administrator
            </h1>
         </div>
         <h2 className="text-xl font-bold leading-tight text-gray-800 text-left mb-5">
            {fundName}
         </h2>
         <h3 className="text-xl font-bold leading-tight text-gray-500 text-left mb-5">
            {batchName}
         </h3>
      </>
   );
}
