import { useContext } from 'react';
import { UserContext, isSuperAdmin } from '../../contexts/UserContext';
import useSignout from '../../hooks/useSignout';
import Navbar from './Navbar';
import NavItem from './NavItem';
import NavItems from './NavItems';

export default function AdminNavbar() {
   const adminSignout = useSignout();
   const { user } = useContext(UserContext);
   return (
      <Navbar homepageUrl="/admin">
         <NavItems>
            <NavItem href="/admin/funds">Funds</NavItem>
            <NavItem href="/admin/users">Users</NavItem>
            <NavItem href="/admin/accounts">Accounts</NavItem>
         </NavItems>
         <div className="flex gap-8">
            {isSuperAdmin(user) && (
               <>
                  <NavItem href="/admin/permissions">Permissions</NavItem>
                  <NavItem href="/admin/forms">Forms</NavItem>
                  <NavItem href="/admin/reports">Reports</NavItem>
               </>
            )}
            <button onClick={adminSignout}>Logout</button>
         </div>
      </Navbar>
   );
}
