import { Link } from 'react-router-dom';

interface Props {
   homepageUrl?: string;
   noLink?: boolean;
   children?: React.ReactNode;
   bgColor?: string;
}

export default function Navbar({
   homepageUrl = '/',
   noLink = false,
   children,
   bgColor = 'bg-white',
}: Props) {
   return (
      <nav className={`fixed z-10 w-full shadow-sm ${bgColor}`}>
         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center">
               <div className="flex">
                  <div className="shrink-0 flex">
                     <Link
                        to={homepageUrl}
                        className="flex flex-row align-middle"
                        style={noLink ? { pointerEvents: 'none' } : undefined}
                     >
                        <img
                           src="/img/apex-logo.svg"
                           alt="Apex Group"
                           className="h-8 mx-3"
                        />
                     </Link>
                  </div>
               </div>
               {children}
            </div>
         </div>
      </nav>
   );
}
