import { Link } from 'react-router-dom';

interface Props {
   formId: number;
   fundId: number;
   userEmail: string;
   investorName?: string;
   emailNotification: boolean;
   params: {} | null;
   requiresSecondSignature: boolean;
}

export default function SubDocApiModal({
   formId,
   fundId,
   userEmail,
   investorName,
   emailNotification,
   params,
   requiresSecondSignature,
}: Props) {
   return (
      <p className=" space-y-2 ">
         <p>
            To create a request programatically with these options, make a POST
            to this URL:
         </p>
         <p className=" font-semibold">{`https://investors.tzurmanagement.com/api/fund/${fundId}/request`}</p>
         <div className=" border-black border p-1">
            <p>Content-Type: application/json; encoding="utf8"</p>
            <p>Authorization: Bearer YOUR_API_KEY*</p>
         </div>

         <p>
            with this payload:{' '}
            <pre className="bg-blue-100 p-2">
               {' '}
               {JSON.stringify(
                  {
                     formId,
                     userEmail: userEmail || 'String',
                     investorName: investorName || 'String (optional)',
                     emailNotification,
                     params: params || {},
                     requiresSecondSignature,
                  },
                  undefined,
                  2,
               )}
            </pre>
            <Link
               to="/Apex Israel Portal Forms Api Documentation.pdf"
               target="_blank"
               className="link"
               download
            >
               Download the documentation
            </Link>
            <p>*Contact us if you don't have an API key.</p>
         </p>
      </p>
   );
}
