import { useState } from 'react';
import { mfaApiCall } from '../../../lib/api';
import { MfaValidateMethodProps } from '../MfaValidate';
import MfaEmailForm from './MfaEmailForm';

export default function MfaValidateEmail({
   selectedMethodInfo,
   onSuccess,
}: MfaValidateMethodProps) {
   const [error, setError] = useState('');

   const sendOtcEmail = () => {
      mfaApiCall('post', 'mfa/email/send', {
         mfaAuthId: selectedMethodInfo.id,
      }).catch(err => setError(err.message));
   };

   return (
      selectedMethodInfo && (
         <MfaEmailForm
            mfaAuthId={selectedMethodInfo.id}
            sendOtcEmail={sendOtcEmail}
            onSuccess={onSuccess}
            MethodIdentifier={selectedMethodInfo.identifier}
            error={error}
            setError={setError}
         />
      )
   );
}
