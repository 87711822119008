import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { FundManagerBatchDocumentGet } from 'types/interface';
import BatchDocument from '../../pages/Batch/BatchDocument';
import BatchFolderWrapper, {
   BatchFolderWrapperProps,
} from './BatchFolderWrapper';

interface Props extends BatchFolderWrapperProps {
   batch: FundManagerBatchDocumentGet;
}

export default function BatchRoutes({ batch, ...props }: Props) {
   const match = useRouteMatch();

   return (
      <Switch>
         <Route path={`${match.path}/folder/:currentPath*`}>
            <BatchFolderWrapper {...props} batch={batch} basePath={match.url} />
         </Route>
         <Route path={`${match.path}/:documentId`}>
            <BatchDocument
               batch={batch}
               basePath={match.url}
               baseUrl={props.baseUrl}
            />
         </Route>
         <Route>
            <BatchFolderWrapper {...props} batch={batch} basePath={match.url} />
         </Route>
      </Switch>
   );
}
