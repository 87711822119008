import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import {
   SubDocRequestGet,
   SubDocRequestPatch,
   InvestorRequestGet,
   FormVersionForm,
} from 'types';

import useModifiableGet from '../../hooks/useModifiableGet';
import { api } from '../../lib/api';
import NotFound from '../../pages/NotFound';
import { useAsyncDebounce } from '../../hooks/useAsyncDebounce';
import UserNavbar from '../Navbar/UserNavbar';

// For now we are only supporting localization here. If we expand it to the whole site, remove this
import '../../i18n';
import { useTranslation } from 'react-i18next';
import CompletedSurvey from './CompletedSurvey';
import { uploadFileToAzure } from '../../lib/file';
import StaleSurveyModal from '../StaleSurveyModal';

const Survey = lazy(() => import('./Survey'));

function SurveyForm({ match }: RouteComponentProps<{ id: string }>) {
   const { id } = match.params;

   const [data, error, loading, updateRequest] =
      useModifiableGet<SubDocRequestGet>(
         `request/${id}?includeSurveyCode=true`,
      );

   // There are 3 possible states
   // - Marked as complete (submitted). Cannot edit.
   // - Completed but not submitted. Can review, edit, or submit.
   const [surveyCompleted, setSurveyCompleted] = useState(false);
   // If it is completed but not submitted, by default on load
   // it is in review mode
   // but user can go back to edit mode
   const [editing, setEditing] = useState<boolean>(false);

   const [showStaleSurveyModal, setShowStaleSurveyModal] = useState(false);

   const latestHash = useRef<string | undefined>(data?.responseHash);
   useEffect(() => {
      latestHash.current = data?.responseHash;
   }, [data?.responseHash]);

   const debouncedUpdateRequest = useAsyncDebounce(async (survey: any) => {
      try {
         const res = await api.patch<SubDocRequestGet, SubDocRequestPatch>(
            `request/${id}`,
            {
               response: survey.data,
               responseHash: latestHash.current,
            },
         );

         // Can't rely on the effect running before the next debounced call
         latestHash.current = res.responseHash;

         return res;
      } catch (err) {
         if (err.message === 'Response hash does not match.') {
            setShowStaleSurveyModal(true);
         } else {
            alert(err);
         }
         throw err;
      }
   });

   const handleConfirmSubmit = async () => {
      const res = await api.patch<SubDocRequestGet, SubDocRequestPatch>(
         `request/${id}`,
         { completed: true },
      );
      updateRequest(res);
   };

   //submit request to send to second signator
   const handleSendToSecondSignator = async (email: string) => {
      const res = await api.patch<SubDocRequestGet, SubDocRequestPatch>(
         `request/${id}`,
         { secondSignatorEmail: email },
      );
      updateRequest(res);
   };

   const [availableLanguages, setAvailableLanguages] = useState<
      [string, string][]
   >([]);
   const [language, setLanguage] = useState<string>();

   const { t, i18n } = useTranslation('survey');

   useEffect(() => {
      let oldLanguage = i18n.language;
      if (language) {
         i18n.changeLanguage(language);
         return () => void i18n.changeLanguage(oldLanguage); // Change language back to English, until we localize the rest of the site
      }
   }, [language]);

   const handleFileUpload = async (file: File) => {
      // Get SAS URL
      const { sasUrl, azureId } = await api.get<{
         sasUrl: string;
         azureId: string;
      }>(`request/${id}/blob-sas-url`);
      // Upload file
      await uploadFileToAzure(file, sasUrl);
      return { file, content: azureId };
   };

   const handleFileDownload = (content: string) => {
      // Get SAS URL
      return api.get<string>(`request/${id}/blob-sas-url/${content}`);
   };

   const backToEdit = () => {
      setEditing(true);
   };

   const handleComplete = (model: any) => {
      debouncedUpdateRequest(model)
         .then(res => {
            setEditing(false);
            setSurveyCompleted(true);

            updateRequest({
               ...res,
               response: model.data,
               surveyCode: data?.surveyCode,
            });
         })
         .catch(console.error);
   };

   if (data) {
      const params = data.params || {};
      data.params = { ...params, isFinalTime: data.isFinalSignator };
   }

   //merge in params into repsonse if it wasn't saved in the backend
   if (data?.params) {
      data.response = { ...data.response, params: data.params };
   }

   const isEditMode = !data?.completedAt && (editing || !surveyCompleted);

   if (!id || ['Not Found', 'Forbidden'].includes(error || ''))
      return <NotFound />;

   return (
      <>
         <UserNavbar />
         {data && (
            <div dir={i18n.dir()}>
               <div className="pt-20 max-w-5xl mx-auto mb-8" dir={i18n.dir()}>
                  <>
                     {availableLanguages?.length > 1 && (
                        <select
                           onChange={e => setLanguage(e.target.value)}
                           value={language}
                           className="float-right"
                        >
                           {availableLanguages.map(([lang, label]) => (
                              <option key={lang} value={lang}>
                                 {label}
                              </option>
                           ))}
                        </select>
                     )}
                     <div className="flex items-center flex-col text-md sm:text-lg lg:text-2xl text-gray-600 cursor-default">
                        {data.fundLogo ? (
                           <img src={data.fundLogo} className="h-20" />
                        ) : (
                           <div>{data.fundName}</div>
                        )}
                     </div>
                  </>
               </div>

               {data ? (
                  <Suspense
                     fallback={
                        <div className="justify-center text-center">
                           Loading...
                        </div>
                     }
                  >
                     {' '}
                     {!isEditMode ? (
                        <CompletedSurvey
                           submitted={Boolean(data.completedAt)}
                           completedAt={data.completedAt}
                           requestId={Number(id)}
                           onBackToEdit={backToEdit}
                           onConfirmSubmit={handleConfirmSubmit}
                           isFinalSignator={Boolean(data.isFinalSignator)}
                           secondSignatureAssigned={Boolean(
                              data.secondSignatureAssigned,
                           )}
                           onSendToSecondSignator={handleSendToSecondSignator}
                        />
                     ) : (
                        <div className="unreset">
                           <div className="max-w-7xl mx-auto">
                              {showStaleSurveyModal && (
                                 <StaleSurveyModal
                                    onClose={() =>
                                       setShowStaleSurveyModal(false)
                                    }
                                 />
                              )}
                              <Survey
                                 onAvailableLanguages={setAvailableLanguages}
                                 onChangeLanguage={setLanguage}
                                 language={language}
                                 theme="defaultV2"
                                 json={data.surveyCode}
                                 params={data.params}
                                 responseData={data.response}
                                 alwaysStartFromBeginning={
                                    data.requiresSecondSignature &&
                                    data.isFinalSignator
                                 }
                                 onFileDownload={handleFileDownload}
                                 onValueChanged={(model: any) => {
                                    debouncedUpdateRequest(model).catch(err =>
                                       console.error(err),
                                    );
                                 }}
                                 onComplete={handleComplete}
                                 onSurveyCompleted={() =>
                                    setSurveyCompleted(true)
                                 }
                                 onFileUpload={handleFileUpload}
                              />
                           </div>
                        </div>
                     )}
                  </Suspense>
               ) : (
                  <div className="justify-center text-center">Loading...</div>
               )}
            </div>
         )}
      </>
   );
}

export default SurveyForm;
