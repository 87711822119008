import TableData from '../../components/Table/TableData';
import TableRow from '../../components/Table/TableRow';

interface Props {
   name: string;
   onClick: () => void;
}
export default function FolderRow({ name, onClick }: Props) {
   return (
      <TableRow onClick={onClick} displayHoverEffect>
         <TableData>
            <div className="flex">
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
               >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                  />
               </svg>
               {name}
            </div>
         </TableData>
         <TableData>{''}</TableData>
      </TableRow>
   );
}
