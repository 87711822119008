export function formatInputDate(date: Date) {
   return new Date(date).toISOString().split('T')[0];
}

export function formatDateString(date: Date | null, includeHours = false) {
   if (!date) return '';
   const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
   };

   if (includeHours) {
      options.hour = '2-digit';
      options.minute = '2-digit';
   }

   return new Date(date).toLocaleDateString(undefined, options);
}
