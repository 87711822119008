import useGetNonTypeSafe from '../hooks/useGetNonTypeSafe';
import { api } from '../lib/api';
import { downloadBlob, downloadDocumentFromPath } from '../lib/file';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubDocRequestGet } from 'types';

interface Props {
   requestId: number;
   rawData?: boolean;
}

type Downloadables = NonNullable<SubDocRequestGet['attachments']>;

export default function RequestDownloads({ requestId, rawData }: Props) {
   const [downloadables, error] = useGetNonTypeSafe<Downloadables>(
      `request/${requestId}/paths`,
   );

   const sortedDownloadables = useMemo<Downloadables>(() => {
      return [...(downloadables ?? [])].sort((a, b) =>
         a.name.localeCompare(b.name),
      );
   }, [downloadables]);

   const downloadAll = () => api.download(`request/${requestId}/download-all`);

   const { t } = useTranslation('survey');

   return (
      <>
         <div className="flex justify-end">
            <button className="basic-btn" onClick={downloadAll}>
               {t('Download All')}
            </button>
         </div>

         {downloadables ? (
            <table className="rounded-t-lg mt-5 w-full bg-gray-200 text-gray-800 ">
               <thead>
                  <tr className="text-left rtl:text-right border-b-2 border-gray-300">
                     <th className="px-4 py-3">{t('Name')}</th>
                     <th className="px-4 py-3">{t('Download')}</th>
                  </tr>
               </thead>
               <tbody>
                  {rawData && <RawDataDownloadable requestId={requestId} />}
                  {sortedDownloadables.map(d => (
                     <RequestDownloadRow
                        key={d.url}
                        label={d.name}
                        onDownload={() => downloadFile(d.url, d.name)}
                     />
                  ))}
               </tbody>
            </table>
         ) : (
            <div className="text-center">Loading...</div>
         )}
      </>
   );
}

async function downloadFile(path: string, filename: string) {
   const res = await api.get(path);
   if (res instanceof Blob) {
      downloadBlob(res, filename);
   } else if (typeof res === 'string') {
      downloadDocumentFromPath(res, filename);
   }
}

interface DownloadableProps {
   requestId: number;
}
function RawDataDownloadable({ requestId }: DownloadableProps) {
   const download = () =>
      api.get(`request/${requestId}/response`).then(res =>
         downloadBlob(
            new File([JSON.stringify(res, undefined, 3)], 'raw-data.json', {
               type: 'application/json',
            }),
            'raw-data.json',
         ),
      );

   return <RequestDownloadRow label="Raw Data (JSON)" onDownload={download} />;
}

interface RequestDownloadRowProps {
   label: string;
   onDownload: () => void;
}

function RequestDownloadRow({ label, onDownload }: RequestDownloadRowProps) {
   const { t } = useTranslation('survey');
   return (
      <tr className="text-left bg-gray-100 border-b border-gray-200 hover:bg-gray-200">
         <td className="px-4 py-3">{label}</td>
         <td className="px-4 py-3">
            <button className="action-btn" onClick={onDownload}>
               {t('Download')}
            </button>
         </td>
      </tr>
   );
}
