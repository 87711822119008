import { useHistory, useLocation } from 'react-router-dom';
import Documents, { DocumentFilters, formatDate } from './Documents';

export type DocumentBaseUrl =
   | 'me/documents'
   | `account/${number}/documents`
   | `user/${number | string}/documents`
   | `fund/${number}/documents`;

interface Props {
   baseUrl: DocumentBaseUrl;
   adminPage?: boolean;
   fundId?: number;
   accountId?: number;
   accountFiltersUrl?: string | null;
   fundPage?: boolean;
   onSendDocumentToUser?: (documentId: number) => void;
}

function DocumentsWrapper(props: Props) {
   const history = useHistory();
   const location = useLocation();
   const urlParams = new URLSearchParams(location.search);

   const limit = Number(urlParams.get('limit') ?? 10);
   const pageNum = Number(urlParams.get('pageNum') ?? 1);

   const getOrder = (): 'ASC' | 'DESC' => {
      const order = urlParams.get('order') ?? '';
      switch (order) {
         case 'ASC':
         case 'DESC':
            return order;
         default:
            return 'DESC';
      }
   };

   const convertStringToDate = (str: string) => {
      if (!str) {
         return undefined;
      }

      const d = new Date(str);
      const day = d.getDate();
      const month = d.getMonth() + 1;
      const year = d.getFullYear();
      return { day, month, year };
   };

   const filters = {
      order: getOrder(),
      sortBy: urlParams.get('sortBy') ?? 'publishedAt',
      documentName: urlParams.get('documentName') ?? '',
      fund: urlParams.get('fund') ?? '',
      docType: urlParams.get('docType') ?? '',
      account: urlParams.get('account') ?? '',
      fromDate: convertStringToDate(urlParams.get('fromDate') ?? ''),
      toDate: convertStringToDate(urlParams.get('toDate') ?? ''),
      bulk: urlParams.get('bulk') ?? '',
      documentStatus: urlParams.get('documentStatus') ?? '',
   };

   const existingFilters: { [key: string]: any } = {};
   for (let [key, value] of Object.entries(filters)) {
      if (value) {
         existingFilters[key] = value;
      }
   }

   const setFilters = (filters: DocumentFilters) => {
      const params: { [key: string]: any } = { limit };
      for (let [key, value] of Object.entries(filters)) {
         if (['fromDate', 'toDate'].includes(key) && value) {
            value = formatDate(value);
         }
         if (key === 'docType' && value === 0) {
            params[key] = value;
         }
         if (value) {
            params[key] = value;
         }
      }
      history.push(`?${new URLSearchParams(params)}`);
   };

   const setLimit = (limit: number) => {
      urlParams.set('limit', String(limit));
      history.push(`?${new URLSearchParams(urlParams)}`);
   };

   const setPageNum = (pageNum: number) => {
      urlParams.set('pageNum', String(pageNum));
      history.push(`?${new URLSearchParams(urlParams)}`);
   };

   return (
      <Documents
         {...props}
         filters={existingFilters}
         setFilters={setFilters}
         pageNum={pageNum}
         setPageNum={setPageNum}
         limit={limit}
         setLimit={setLimit}
      />
   );
}

export default DocumentsWrapper;
