import type { ApplicationInsights } from '@microsoft/applicationinsights-web';

interface ReportingConsole extends Console {
   appInsights: ApplicationInsights;
   report: (data: any[], severity: number) => void;
}
const ReportingConsole = function ReportingConsole(
   this: ReportingConsole,
   appInsights: ApplicationInsights,
) {
   this.appInsights = appInsights;
   this.error = this.error.bind(this);
   this.warn = this.warn.bind(this);
} as unknown as { new (appInsights: ApplicationInsights): ReportingConsole };
ReportingConsole.prototype = Object.setPrototypeOf(
   {
      report(data: any[], severityLevel: number) {
         const message =
            typeof data[0] === 'string'
               ? data[0]
               : data[0] instanceof Error
                 ? data[0].message
                 : JSON.stringify(data[0]);
         this.appInsights.trackTrace(
            { message, severityLevel },
            data.length === 2 ? data[1] : data.slice(1),
         );
      },
      error(this: ReportingConsole, ...data: any[]) {
         super.error(...data);
         this.report(data, 3);
      },
      warn(this: ReportingConsole, ...data: any[]) {
         super.warn(...data);
         this.report(data, 2);
      },
   },
   window.console,
);
export default ReportingConsole;
