import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FundManagerBatchDocumentGet } from 'types/interface';
import DocumentViewer from '../../components/DocumentViewer';
import { formatDateString } from '../../lib/date';
import { BaseUrl, getDocumentFromApi } from './download';

interface Props {
   batch: FundManagerBatchDocumentGet;
   basePath: string;
   baseUrl: BaseUrl;
}

export default function BatchDocument({ batch, basePath, baseUrl }: Props) {
   const match = useRouteMatch<{ batchId: string; documentId: string }>();
   const history = useHistory();

   const { batchId, documentId } = match.params;
   const [documentUrl, setDocumentUrl] = useState('');

   useEffect(() => {
      getDocumentFromApi(baseUrl, Number(documentId), Number(batchId)).then(
         res => setDocumentUrl(res),
      );
   }, [documentId]);

   const document = batch.fundManagerDocuments.find(
      doc => doc.id === Number(documentId),
   );
   const date = formatDateString(batch.createdAt);

   const handleBack = () => {
      if (history.length > 1) {
         history.goBack();
      } else {
         history.push(basePath);
      }
   };

   return (
      <>
         {document && documentUrl && (
            <DocumentViewer
               filename={document.name}
               date={date}
               url={documentUrl}
               onBack={handleBack}
            />
         )}
      </>
   );
}
