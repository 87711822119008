import { SVGProps } from 'react';
import { MfaMethod, MfaMethodTypes } from 'types';

export const displayName: Record<MfaMethodTypes, string> = {
   email: 'Receive a code via email',
   app: 'Code from authenticator app',
   passkey: 'Use a passkey',
};

export const displayIcon = {
   email: (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24"
         strokeWidth={1.5}
         stroke="currentColor"
         className="size-6"
      >
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
         />
      </svg>
   ),
   app: (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24"
         strokeWidth={1.5}
         stroke="currentColor"
         className="size-6"
      >
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
         />
      </svg>
   ),
   passkey: (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24"
         strokeWidth={1.5}
         stroke="currentColor"
         className="size-6"
      >
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z"
         />
      </svg>
   ),
} satisfies Record<MfaMethodTypes, SVGProps<SVGSVGElement>>;

interface Props {
   selectableMethods: MfaMethod[];
   onSuccess: (mfaMethod: MfaMethod) => void;
}

export default function mfaMethodSelect({
   selectableMethods,
   onSuccess,
}: Props) {
   return (
      <>
         <h2 className="mb-6 text-left text-2xl font-extrabold text-gray-900">
            <>Multi-factor authentication</>
         </h2>
         <div className="p-4 bg-gray-100 rounded-md text-left my-6">
            <p>
               To keep your account secure, please select a method to verify
               your identity:
            </p>
         </div>

         <div className="">
            {selectableMethods.map(item => (
               <button
                  type="button"
                  onClick={() => onSuccess(item)}
                  className="w-full flex flex-col items-center justify-center py-4 px-4 my-4 border border-transparent rounded-md 
                  shadow-sm  text-white bg-blue-600 
                  hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
               >
                  <span>{displayIcon[item.methodType]}</span>
                  <span className="font-medium">
                     {displayName[item.methodType]}
                  </span>
                  {item.identifier && (
                     <span className="text-sm ">{item.identifier}</span>
                  )}
               </button>
            ))}
         </div>
      </>
   );
}
