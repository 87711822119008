import { useRef } from 'react';

export function useAsyncDebounce<
   F extends (...args: Parameters<F>) => Promise<unknown>,
>(callback: F): F {
   type R = Awaited<ReturnType<F>>;
   // Keep track of state
   let inProgress = useRef<boolean>(false);
   // Next set of args to call when current call finishes
   let nextCall = useRef<Parameters<F>>();
   let nextPromise = useRef<Promise<R>>();
   let nextResolve = useRef<(res: R) => void>();
   let nextReject = useRef<(err: Error) => void>();

   const debounced = (...args: Parameters<F>): Promise<R> => {
      // If we're not already in progress, call the callback
      if (!inProgress.current) {
         const toCall: Parameters<F> = args;
         // Clear nextCall so we don't call again
         nextCall.current = undefined;
         // Set inProgress to true
         inProgress.current = true;
         // Call the callback
         const ret = callback.apply(undefined, toCall) as Promise<R>;
         // Resolve or reject any promises waiting from previous calls
         ret.then(
            res => nextResolve.current?.(res),
            err => nextReject.current?.(err),
         )
            // Clean up and call the next call if any waiting
            .finally(() => {
               inProgress.current = false;
               if (nextCall.current) {
                  debounced.apply(null, nextCall.current);
               }
            });
         // Return the original result
         return ret;
      } else {
         // Set the next set of args to call
         nextCall.current = args;
         // Return a promise that resolves when the next call finishes
         if (!nextPromise.current) {
            nextPromise.current = new Promise((resolve, reject) => {
               nextResolve.current = resolve;
               nextReject.current = reject;
            });
         }
         return nextPromise.current;
      }
   };

   return debounced as F;
}
