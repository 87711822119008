interface Props {
   count: number;
   pageNum: number;
   resultLength: number;
   limit: number;
   setPageNum: (pageNum: number) => void;
   setLimit?: (limit: number) => void;
   itemType?: string;
   paginationOptions?: number[];
   className?: string;
}

function Pagination({
   resultLength,
   limit,
   count,
   pageNum,
   setPageNum,
   setLimit,
   itemType,
   paginationOptions,
   className,
}: Props) {
   const startNumber = (pageNum - 1) * limit + 1;

   const endNumber = startNumber + resultLength - 1;

   const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setLimit && setLimit(Number(value));
   };

   return (
      <nav
         className={
            className + ' bg-white p-3 border-t border-gray-200 sm:px-6'
         }
         aria-label="Pagination"
      >
         <div className="max-w-7xl flex items-center justify-between mx-auto px-4 sm:px-6 lg:px-8">
            <div className="hidden sm:block justify-self-start">
               <p className="text-sm text-gray-700">
                  Showing
                  <span className="font-medium"> {startNumber} </span>
                  to
                  <span className="font-medium"> {endNumber} </span>
                  of
                  <span className="font-medium"> {count} </span>
                  {itemType ?? 'results'}
               </p>
            </div>

            {setLimit && (
               <div className="flex justify-center justify-self-center">
                  <p className="text-sm text-gray-700">
                     Display
                     <select
                        className="border border-gray-200 mx-1"
                        value={limit}
                        onChange={handleSelectChange}
                     >
                        {paginationOptions?.map(p => (
                           <option value={p} key={p}>
                              {p}{' '}
                           </option>
                        ))}
                     </select>
                     {itemType ?? 'results'} per page
                  </p>
               </div>
            )}

            <div className="flex justify-between sm:justify-end justify-self-end">
               {pageNum !== 1 && (
                  <button
                     className="relative inline-flex items-center px-6 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                     onClick={() => setPageNum(pageNum - 1)}
                  >
                     Previous
                  </button>
               )}

               {Boolean(count && endNumber !== count) && (
                  <button
                     className="ml-3 relative inline-flex items-center px-6 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                     onClick={() => setPageNum(pageNum + 1)}
                  >
                     Next
                  </button>
               )}
            </div>
         </div>
      </nav>
   );
}

export default Pagination;
