import { Menu } from '@headlessui/react';

import Dropdown from './Dropdown';

interface ListDropDownOption {
   label: string;
   value: any;
}

interface Props {
   title: string;
   options: ListDropDownOption[];
   onChange: (value: any) => void;
   selectedValue?: any;
}

function ListDropdown({ title, options, onChange, selectedValue }: Props) {
   const selectedTitle =
      selectedValue || selectedValue === 0
         ? `${title}: ${
              options.find(option => option.value === selectedValue)?.label
           }`
         : title;

   return (
      <Dropdown title={selectedTitle || ''}>
         <Menu.Items
            static
            className="z-10 absolute left-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none max-h-60 overflow-y-auto"
         >
            <div className="py-1">
               {options.map(option => (
                  <Menu.Item
                     as="div"
                     key={option.value}
                     className={`cursor-pointer flex w-full px-4 py-2 text-sm leading-5 text-left hover:bg-gray-100 text-gray-900`}
                     onClick={() => onChange(option.value)}
                  >
                     {option.label}
                  </Menu.Item>
               ))}
            </div>
         </Menu.Items>
      </Dropdown>
   );
}

export default ListDropdown;
