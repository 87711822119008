import { useState } from 'react';

import LoggedOutLayout from '../components/LoggedOutLayout';

import { Link } from 'react-router-dom';

import { forgotPassword } from '../lib/api';

function Login() {
   const [email, setEmail] = useState('');

   const [postValue, setPostValue] = useState('');
   const [postErrorMessage, setPostErrorMessage] = useState('');

   const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      forgotPassword({ email })
         //returns a 202
         .then(res => setPostValue('ok'))
         .catch(err => setPostErrorMessage(err.message));
   };

   return (
      <LoggedOutLayout>
         {/* <!-- Centered White Card Container -- Content Starts Here--> */}

         <h2 className="mb-6 text-left text-3xl font-extrabold text-gray-900">
            Forgot Password
         </h2>

         {postValue ? (
            <div> An email was sent to {email} to reset your password.</div>
         ) : (
            <form className="space-y-6" onSubmit={handleSubmit}>
               <div>
                  <label
                     htmlFor="email"
                     className="block text-sm font-medium text-gray-700"
                  >
                     Email address
                  </label>
                  <div className="mt-1">
                     <input
                        onChange={e => setEmail(e.target.value)}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                     />
                  </div>
               </div>

               <div>
                  <button
                     type="submit"
                     className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                     Submit
                  </button>
               </div>
            </form>
         )}

         <Link className="my-4 block text-sm text-blue-400" to="/">
            Back to login
         </Link>

         {/* <!-- Centered White Card Container -- Content Ends Here--> */}
      </LoggedOutLayout>
   );
}

export default Login;
