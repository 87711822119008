import AdminLayout from '../AdminLayout';
import LoggedInLayout from '../LoggedInLayout';
import BatchAdminFolder, { BatchAdminFolderProps } from './BatchAdminFolder';
import BatchFolder, { BatchFolderProps } from './BatchFolder';

export interface BatchFolderWrapperProps extends BatchAdminFolderProps {
   isAdminPage?: boolean;
}

export default function BatchFolderWrapper({
   isAdminPage,
   ...props
}: BatchFolderWrapperProps) {
   return (
      <>
         {isAdminPage ? (
            <AdminLayout>
               <BatchAdminFolder {...props} />
            </AdminLayout>
         ) : (
            <LoggedInLayout>
               <BatchFolder {...props} />
            </LoggedInLayout>
         )}
      </>
   );
}
