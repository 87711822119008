import LoggedOutLayout from '../components/LoggedOutLayout';

function Forbidden() {
   return (
      <LoggedOutLayout>
         <h2>Access to this page is forbidden.</h2>
      </LoggedOutLayout>
   );
}

export default Forbidden;
