import { DocTypeGet, DocumentGet } from 'types';
import { api } from '../lib/api';

import DocumentTypeBadge from './DocumentTypeBadge';
import FormModal from './FormModal';
import EditDocument from '../pages/admin/document/EditDocument';
import DocumentRow from './DocumentRow';
import { useState } from 'react';

import Banner from './Banner';
import { downloadDocumentFromPath } from '../lib/file';
import { Link, useHistory } from 'react-router-dom';

interface DocumentInterface extends Omit<DocumentGet, 'isPublished'> {}

export interface DocumentViewerProps {
   url: string;
   filename: string;
   date: string;
   documentType: DocTypeGet;
}

interface DocumentCardProps {
   setSelectedDocument?: ({
      url,
      filename,
      date,
      documentType,
   }: DocumentViewerProps) => void;
   handleEdit?: () => void;
   isTableView?: boolean;
   checked?: boolean;
   onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
   adminPage?: boolean;
   refresh: () => void;
   fundPage?: boolean;
   document: DocumentInterface;
   onSendDocumentToUser?: (documentId: number) => void;
}

const showNewLabel = (publishedAt: Date | null) => {
   if (!publishedAt) return false;

   const days = 5;
   const daysInMs = days * 24 * 60 * 60 * 1000;

   const today = new Date().toDateString();
   const datePublished = new Date(publishedAt).toDateString();

   return (
      new Date(today).getTime() - new Date(datePublished).getTime() <= daysInMs
   );
};

const formatDateString = (date: Date | null) => {
   if (!date) return '';
   const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
   };

   return new Date(date).toLocaleDateString(undefined, options);
};

export default function DocumentCard({
   setSelectedDocument,
   isTableView,
   checked,
   onChange,
   adminPage,
   refresh,
   fundPage,
   document,
   onSendDocumentToUser,
}: DocumentCardProps) {
   const {
      id,
      fileName,
      publishedAt,
      documentDate,
      documentType,
      fund,
      account,
      emailAttachment,
      emailNotificationAt,
      bulk,
      depublishedAt,
      emailNotificationSent,
   } = document;

   const history = useHistory();

   const docDate = formatDateString(documentDate);
   const publishedDate = formatDateString(publishedAt);
   const isNewDocument = showNewLabel(publishedAt);
   const fundName = fund.name;
   const docType = documentType;
   const accountName = account?.name;

   //check if published date has past
   const isPublished =
      publishedAt && new Date(publishedAt || Date()) < new Date();
   const isFuturePublished = publishedAt && !isPublished;
   const noPublishDate = publishedAt === null;

   const emailNotification = Boolean(emailNotificationAt);

   const [showEditModal, setShowEditModal] = useState(false);
   const [documentId, setDocumentId] = useState(0);
   const [actionError, setActionError] = useState('');

   const handleEdit = (e: any, id: number) => {
      setDocumentId(id);
      setShowEditModal(true);
   };

   const getDocumentFromApi = async () => {
      return api.get(`document/${id}/download`);
   };

   const downloadDocument = async () => {
      const res = (await getDocumentFromApi()) as string;
      downloadDocumentFromPath(res);
   };

   const unpublishDocument = (e: any, id: number) => {
      const confirmed = window.confirm(
         'Are you sure you would like to depublish this document?',
      );

      if (!confirmed) return;

      api.delete(`document/${id}`)
         .then(res => refresh())
         .catch(err =>
            setActionError('Could not depublish this document at this time.'),
         );
   };

   const documentViewHref = adminPage
      ? `/admin/document/${id}`
      : `/document/${id}`;
   const documentViewerData = {
      filename: fileName,
      date: docDate,
      documentType: docType,
   };

   return (
      <>
         {actionError && <Banner type="error"> {actionError} </Banner>}
         {documentId ? (
            <FormModal
               title="Edit Document"
               display={showEditModal}
               onClose={() => setShowEditModal(false)}
            >
               <EditDocument
                  refresh={refresh}
                  closeModal={() => setShowEditModal(false)}
                  fundId={fund.id}
                  accountId={account?.id}
                  documentId={documentId}
               />
            </FormModal>
         ) : null}

         {isTableView ? (
            <DocumentRow
               bulkId={bulk && bulk.id}
               fundPage={fundPage}
               emailNotification={emailNotification}
               emailAttachment={emailAttachment}
               isPublished={Boolean(isPublished)}
               onUnpublishDocument={unpublishDocument}
               documentId={id}
               adminPage={adminPage}
               onSendDocumentToUser={onSendDocumentToUser}
               onChange={onChange}
               checked={checked ?? false}
               isNew={isNewDocument}
               fileName={fileName}
               publishedDate={publishedDate}
               docDate={docDate}
               fundName={fundName}
               docType={docType}
               downloadDocument={downloadDocument}
               accountName={accountName}
               handleEdit={handleEdit}
               isDepublished={Boolean(depublishedAt)}
               isFuturePublished={!!isFuturePublished}
               noPublishDate={noPublishDate}
               emailNotificationSent={emailNotificationSent}
            />
         ) : (
            <div className="mt-6 bg-white overflow-hidden shadow rounded-lg">
               {/* Some padding around content */}
               <div className="px-3 py-3">
                  <div className="flex items-center">
                     <div className="w-0 flex-1">
                        <div className="flex">
                           {/* New Badge*/}
                           {showNewLabel(documentDate) && (
                              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 uppercase tracking-wider">
                                 New
                              </span>
                           )}

                           {/* Date */}
                           <div className="ml-2 text-sm font-normal text-gray-500 truncate">
                              {docDate}
                           </div>
                        </div>
                        {/* File / Doc Name */}
                        <div className="mt-2 text-lg font-medium text-gray-900">
                           {fileName}
                        </div>
                        {/* Fund Name*/}
                        <div className="text-base font-light text-gray-600">
                           <div>{fund.name}</div>
                           {accountName ? (
                              <div className="text-xs">{accountName}</div>
                           ) : null}
                        </div>
                     </div>
                  </div>
               </div>
               {/* Card Footer Section */}
               <div className="bg-gray-50 p-3">
                  <div className="flex justify-between">
                     {/* Doc / File Type */}
                     <DocumentTypeBadge docType={documentType} />
                     {/* Actions */}
                     <div className="flex">
                        <Link
                           to={{
                              pathname: documentViewHref,
                              state: documentViewerData,
                           }}
                           className="object-right px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                        >
                           View
                        </Link>

                        {adminPage && handleEdit && (
                           <button
                              onClick={e => handleEdit(e, id)}
                              type="button"
                              className="object-right px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                           >
                              Edit
                           </button>
                        )}

                        <button
                           onClick={downloadDocument}
                           className="flex items-center ml-2 px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                        >
                           Download
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </>
   );
}
