import { useHistory, useLocation } from 'react-router-dom';
import useGet from '../../hooks/useGet';
import DateInput from '../DatePlaceholder';
import ListDropdown from '../ListDropdown';

interface RequestQuery {
   categoryId: number;
   reportingPeriod: string;
}

interface Props {
   categoriesUrl:
      | `fund/${number}/fund-manager-batch/categories`
      | `files/batches/categories`;
}

export default function BatchesFilter({ categoriesUrl }: Props) {
   const [categories, categoriesError] = useGet(categoriesUrl);
   const history = useHistory();
   const location = useLocation();

   const urlParams = new URLSearchParams(location.search);

   const handleFilterClick = (value: any, filter: keyof RequestQuery) => {
      if (!value) {
         urlParams.delete(filter);
      } else {
         urlParams.set(filter, value);
      }

      history.push(`?${urlParams}`);
   };

   const categoriesFilter =
      categories?.map(category => ({
         label: category.name,
         value: category.id,
      })) || [];

   //filters
   const categoryId = urlParams.get('categoryId') ?? '';
   const reportingPeriod = urlParams.get('reportingPeriod') ?? '';

   return (
      <div className="flex flex-wrap gap-2 mb-2">
         <ListDropdown
            title="Category"
            options={[
               { label: 'All Categories', value: '' },
               ...categoriesFilter,
            ]}
            onChange={value => handleFilterClick(value, 'categoryId')}
            selectedValue={categoryId ? Number(categoryId) : ''}
         />
         <div>
            <DateInput
               placeholder="Reporting Period"
               onChange={value => handleFilterClick(value, 'reportingPeriod')}
               value={reportingPeriod}
            />
         </div>
      </div>
   );
}
