import { jwtDecode } from 'jwt-decode';
import type { AdminToken, UserToken, MfaOrUserToken, AuthToken } from 'types';
import type { ApiMethodPath, ApiRequest, ApiResponse } from 'types/index2';
import { ApiError } from './ApiError';
import { downloadBlob } from './file';
import { parse } from 'content-disposition-header';
import { MfaPasskeyAuthentication } from 'types/interface';

interface HeaderOptions {
   'Content-Type'?: string;
   Authorization: string;
   [key: string]: any;
}

export function removeAuthToken() {
   localStorage.removeItem('jwttoken');
}

export function getAuthToken() {
   return localStorage.getItem('jwttoken');
}

export function getAuthTokenData(): AuthToken | null {
   const token = getAuthToken();
   return token ? jwtDecode(getAuthToken()!) : null;
}

function setAuthToken(token: string) {
   localStorage.setItem('jwttoken', token);
}

export function getImpersonationId() {
   return sessionStorage.getItem('impersonationId');
}

const getAuthHeaders = (token = getAuthToken()) => {
   const headers: { [key: string]: string } = {
      Authorization: 'Bearer ' + token,
   };
   const impersonationId = getImpersonationId();
   if (impersonationId) {
      headers['x-impersonation-id'] = impersonationId;
   }
   return headers;
};

interface FetchOptions {
   method: string;
   headers: HeaderOptions;
   body?: string | null | unknown;
}

async function callApi<T>(
   method: string,
   path: string,
   body?: RequestInit['body'] | {},
   headers?: Record<string, string>,
   thirdPartyToken?: string,
   returnResponseData = false,
   isRetry = false,
): Promise<T> {
   let fetchOptions: RequestInit = {
      method,
      headers: { ...headers, ...getAuthHeaders(thirdPartyToken) },
   };

   if (body) {
      if (
         typeof body === 'object' &&
         !(body instanceof File) &&
         !(body instanceof FormData)
      ) {
         fetchOptions.body = JSON.stringify(body);
         if (!headers?.['Content-Type']) {
            fetchOptions.headers = {
               ...fetchOptions.headers,
               'Content-Type': 'application/json',
            };
         }
      } else {
         fetchOptions.body = body as BodyInit;
      }
   }

   const res = await fetch(`/api/${path}`, fetchOptions);

   if (returnResponseData && res.status < 400) {
      return res as any;
   }

   // Retriable error
   if (res.status === 503 && !isRetry) {
      await new Promise(resolve => setTimeout(resolve, 8000));
      return callApi(
         method,
         path,
         body,
         headers,
         thirdPartyToken,
         returnResponseData,
         true,
      );
   }

   const contentType = res.headers.get('content-type');
   let response;

   if (contentType && contentType.includes('application/json')) {
      response = await res.json();
   } else if (contentType?.includes('text')) {
      response = await res.text();
   } else {
      response = await res.blob();
   }

   if (res.status === 401) {
      try {
         if (response === 'jwt expired') {
            const token = await getRefreshToken();

            if (token) {
               setAuthToken(token);
               return callApi(
                  method,
                  path,
                  body,
                  headers,
                  thirdPartyToken,
                  returnResponseData,
               );
            }
         }
      } catch {}
      removeAuthToken();
      window.location.reload();
   }
   if (res.status >= 400) {
      const errorMessage = typeof response === 'string' ? response : res.type;
      throw new ApiError(errorMessage, response);
   }

   return response;
}

async function unauthenticatedApiCall<T>(
   method: string,
   path: string,
   body?: RequestInit['body'] | {},
   headers?: Record<string, string>,
): Promise<T> {
   let fetchOptions: RequestInit = {
      method,
      headers,
      body:
         typeof body === 'object'
            ? JSON.stringify(body)
            : (body as RequestInit['body']),
   };

   if (typeof body === 'object' && !headers?.['Content-Type']) {
      fetchOptions.headers = {
         ...fetchOptions.headers,
         'Content-Type': 'application/json',
      };
   }

   const res = await fetch(`/api/${path}`, fetchOptions);

   const contentType = res.headers.get('content-type');
   let response;

   if (contentType && contentType.includes('application/json')) {
      response = await res.json();
   } else {
      response = await res.text();
   }

   if (res.status >= 400) {
      throw new Error(response);
   }

   return response;
}

export async function mfaApiCall<T>(
   method: string,
   path: string,
   body?: RequestInit['body'] | {},
   headers?: Record<string, string>,
): Promise<T> {
   headers = {
      ...headers,
      ...getAuthHeaders(),
   };

   try {
      return await unauthenticatedApiCall(method, path, body, headers);
   } catch (error) {
      if (error.message === 'jwt expired') {
         try {
            const token = await getRefreshToken();
            if (token) {
               setAuthToken(token);
               return await unauthenticatedApiCall(method, path, body, headers);
            }
         } catch {}
         removeAuthToken();
         window.location.reload();
         return Promise.reject('Unauthorized.');
      } else {
         throw error;
      }
   }
}

export async function mfaApiLogin(
   method: string,
   path: string,
   body?: RequestInit['body'] | {},
   headers?: Record<string, string>,
): Promise<UserToken> {
   const token: string = await mfaApiCall(method, path, body, headers);
   setAuthToken(token);

   return jwtDecode(token) as UserToken;
}

export const api = {
   get: <T>(path: string): Promise<T> => {
      return callApi('GET', path);
   },

   download: async (
      path: string,
      body?: any,
      headers?: Record<string, string>,
   ) => {
      const res = (await callApi(
         body ? 'POST' : 'GET',
         path,
         body,
         headers,
         undefined,
         true,
      )) as Response;

      const contentDisposition = res.headers.get('Content-Disposition');
      const fileName =
         (contentDisposition &&
            parse(contentDisposition).parameters.filename) ||
         'download';
      downloadBlob(await res.blob(), fileName);
   },

   post: <T, Req = T>(
      path: string,
      body: Req,
      returnResponseData = false,
   ): Promise<T> => {
      return callApi(
         'POST',
         path,
         body,
         undefined,
         undefined,
         returnResponseData,
      );
   },

   put: <T, Req = T>(
      path: string,
      body: Req,
      headers?: Record<string, string>,
   ): Promise<T> => {
      return callApi('PUT', path, body, headers);
   },

   delete: <T, Req = T>(path: string, body?: Req): Promise<T> => {
      return callApi('DELETE', path, body);
   },

   patch: <T, Req = T>(path: string, body: Req): Promise<T> => {
      return callApi('PATCH', path, body);
   },
};

// New strictly typed API functions
export function get<T extends ApiMethodPath<'GET'>>(
   path: T,
): Promise<ApiResponse<T, 'GET'>> {
   return callApi('GET', path);
}
export function post<T extends ApiMethodPath<'POST'>>(
   path: T,
   body: ApiRequest<T, 'POST'>,
): Promise<ApiResponse<T, 'POST'>> {
   return callApi('POST', path, body);
}
export function put<T extends ApiMethodPath<'PUT'>>(
   path: T,
   body: ApiRequest<T, 'PUT'>,
): Promise<ApiResponse<T, 'PUT'>> {
   return callApi('PUT', path, body);
}
export function patch<T extends ApiMethodPath<'PATCH'>>(
   path: T,
   body: ApiRequest<T, 'PATCH'>,
): Promise<ApiResponse<T, 'PATCH'>> {
   return callApi('PATCH', path, body);
}
export function remove<T extends ApiMethodPath<'DELETE'>>(
   path: T,
   body?: ApiRequest<T, 'DELETE'>,
): Promise<ApiResponse<T, 'DELETE'>> {
   return callApi('DELETE', path, body);
}

export async function adminAzureLogin(azureToken: string) {
   const token: string = await unauthenticatedApiCall(
      'POST',
      'admin/azurelogin',
      azureToken,
   );
   setAuthToken(token);

   return jwtDecode(token) as AdminToken;
}

export async function userLogin(body: {
   email: string;
   password: string;
}): Promise<MfaOrUserToken> {
   const token: string = await unauthenticatedApiCall('POST', 'login', body);

   setAuthToken(token);

   return jwtDecode(token) as MfaOrUserToken;
}

export async function userAzureLogin(azureToken: string) {
   const token: string = await unauthenticatedApiCall(
      'POST',
      'azurelogin',
      azureToken,
   );
   setAuthToken(token);

   return jwtDecode(token) as UserToken;
}

export async function userGoogleLogin(googleToken: string) {
   const token: string = await unauthenticatedApiCall(
      'POST',
      'googlelogin',
      googleToken,
   );
   setAuthToken(token);

   return jwtDecode(token) as UserToken;
}

export async function anonymousPasskeyLogin(
   auth: MfaPasskeyAuthentication,
): Promise<UserToken> {
   const token = (await unauthenticatedApiCall(
      'POST',
      'login/passkey',
      auth,
   )) as string;
   setAuthToken(token);

   return jwtDecode(token) as UserToken;
}

export function forgotPassword(body: { email: string }) {
   return unauthenticatedApiCall('POST', 'reset-password', body);
}

export function setNewPassword(token: string, body: { password: string }) {
   return unauthenticatedApiCall('PUT', `new-password?token=${token}`, body);
}

export function checkPasswordResetToken(token: string) {
   return unauthenticatedApiCall('GET', `check-reset?token=${token}`);
}

let refreshTokenPromise: Promise<string> | undefined;
async function getRefreshToken() {
   // Copy of the promise in case it gets cleared in the middle
   let promise = refreshTokenPromise;
   if (!promise) {
      promise = refreshTokenPromise = callApi('GET', 'refresh-token');
      promise.finally(() => (refreshTokenPromise = undefined));
   }
   return promise;
}
