import { Router } from 'react-router-dom';
import { Suspense } from 'react';
import type { History } from 'history';
import './App.css';
import Routes from './routes/Routes';
import UserContextProvider from './contexts/UserContext';

const App = ({ history }: { history: History }) => {
   return (
      <div className="App">
         <Suspense fallback="loading">
            <UserContextProvider>
               <Router history={history}>
                  <Routes />
               </Router>
            </UserContextProvider>
         </Suspense>
      </div>
   );
};

export default App;
