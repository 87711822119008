import { RouteComponentProps } from 'react-router-dom';

import UserNavbar from '../../components/Navbar/UserNavbar';
import Request from '../../components/Request';

export default function RequestPage({
   match,
}: RouteComponentProps<{ id: string }>) {
   const requestId = parseInt(match.params.id);
   return (
      <div className="min-h-screen bg-gray-100">
         <UserNavbar />
         <div className="mx-4 py-20 md:py-24">
            <Request requestId={requestId} />
         </div>
      </div>
   );
}
