interface Props {
   onSkip: () => void;
}

export default function FooterMfaOptional({ onSkip }: Props) {
   return (
      <>
         <button
            type="button"
            className="link mt-6 block text-sm text-blue-400"
            onClick={() => onSkip()}
         >
            Skip for now
         </button>
         <div className="text-gray-400 text-xs">
            Note that at some point in the future multi-factor authentication
            will be required
         </div>
      </>
   );
}
