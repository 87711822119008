import React from 'react';

interface Props {
   timeDate: Date | null;
   onChange: (timeDate: Date | null) => void;
}

function TimeDateChooser({ timeDate, onChange }: Props) {
   const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const [hours, minutes] = value.split(':');
      const unixTime = new Date().setHours(Number(hours), Number(minutes));
      const timeString = new Date(unixTime).toTimeString();
      const dateString = new Date(timeDate || Date()).toDateString();
      const d = new Date(`${dateString} ${timeString}`);
      onChange(d);
   };

   const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const timeString = new Date(timeDate || Date()).toTimeString();
      const dateString = new Date(value).toDateString();
      const d = new Date(`${dateString} ${timeString}`);
      onChange(d);
   };

   const formattedDate = new Date(timeDate || Date())
      .toISOString()
      .split('T')[0];

   const formattedTime = (date: Date | null) => {
      if (!date) return '';
      const hours = new Date(date).getHours();
      const minutes = new Date(date).getMinutes();

      const fHours = hours < 10 ? `${0}${hours}` : `${hours}`;
      const fMinutes = minutes < 10 ? `${0}${minutes}` : `${minutes}`;

      return `${fHours}:${fMinutes}`;
   };

   return (
      <>
         <input
            type="date"
            onChange={handleDateChange}
            value={formattedDate}
            className="border-gray-300 border p-2 rounded-md mr-2 my-2"
         />

         <input
            type="time"
            onChange={handleTimeChange}
            className="border-gray-300 border p-2 rounded-md my-2"
            value={formattedTime(timeDate)}
         />
      </>
   );
}

export default TimeDateChooser;
