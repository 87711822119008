import { MfaAuthToken, MfaMethod, MfaMethodTypes } from 'types';
import Toggle from '../Toggle';
import { DeleteButton } from '../DeleteButton';
import { formatDateString } from '../../lib/date';
import FormModal from '../FormModal';
import { useEffect, useState } from 'react';
import MfaSetupSingleMethod from './MfaSetupSingleMethod';
import OfferPasskey from './Passkey/OfferPasskey';
import { mfaApiCall } from '../../lib/api';
import Banner from '../Banner';
import { displayIcon } from './MfaMethodSelect';

export default function ProfileMfaSection() {
   const [error, setError] = useState();
   const [successMsg, setSuccessMsg] = useState<string>();
   const [mfaMethods, setMfaMethods] = useState<MfaAuthToken>();

   const [showMfaModal, setShowMfaModal] = useState(false);
   const [modalPage, setModalPage] =
      useState<keyof typeof modalPages>('setupMethod');

   const [selectedMethodType, setSelectedMethodType] =
      useState<MfaMethodTypes>();

   const emailMfaMethod = (mfaMethods?.methods ?? []).find(
      m => m.methodType === 'email',
   );
   const nonEmailMfaMethods = (mfaMethods?.methods ?? []).filter(
      m => m.methodType !== 'email',
   );
   const nonPasskeyMfaMethods = (mfaMethods?.methods ?? []).filter(
      m => m.methodType !== 'passkey',
   );
   const canAddNonPasskeyMethods = nonPasskeyMfaMethods.length > 0;
   const canDeleteNonPasskeyMethods = nonPasskeyMfaMethods.length > 1;

   useEffect(() => {
      getMfaMethods();
   }, []);

   const getMfaMethods = () => {
      mfaApiCall<MfaAuthToken>('get', `mfa`)
         .then(res => setMfaMethods(res))
         .catch(err => setError(err.message));
   };

   const handleEmailActivateChange = (
      e: React.ChangeEvent<HTMLInputElement>,
   ) => {
      const { checked } = e.target;
      if (checked) {
         showModalSetupPage('email');
      } else {
         deleteMethod(emailMfaMethod!.id);
      }
   };

   const deleteMethod = (mfaAuthId: number) => {
      const confirmed = window.confirm(
         `⚠️ Are you sure you want to delete this MFA method?`,
      );

      if (!confirmed) return;

      mfaApiCall<MfaAuthToken>('delete', `mfa/${mfaAuthId}`)
         .then(res => {
            getMfaMethods();
            setSuccessMsg('Mfa method deleted');
         })
         .catch(err => setError(err.message));
   };

   const showModalSetupPage = (methodType: MfaMethodTypes) => {
      setSelectedMethodType(methodType);
      setModalPage('setupMethod');
      setShowMfaModal(true);
   };

   const setupMethodSuccess = () => {
      getMfaMethods();
      setShowMfaModal(false);
      setSuccessMsg('Mfa method added');
   };

   const modalPages = {
      setupMethod: (
         <MfaSetupSingleMethod
            methodType={selectedMethodType!}
            onSuccess={setupMethodSuccess}
            onCancel={null}
            onSkip={null}
         />
      ),

      offerPasskey: (
         <OfferPasskey
            onSuccess={setupMethodSuccess}
            onCancel={() => setShowMfaModal(false)}
         />
      ),
   } satisfies Record<string, JSX.Element>;

   return (
      <div className="col-span-6 sm:col-span-6">
         {successMsg && <Banner type="success"> {successMsg} </Banner>}
         {error && <Banner type="error"> {error} </Banner>}

         <FormModal
            title="Update Multi-Factor Authentication"
            display={showMfaModal}
            onClose={() => setShowMfaModal(false)}
         >
            {modalPages[modalPage]}
         </FormModal>

         <div className="pb-1 md:pb-5 sm:flex md:items-center">
            <h3 className="text-lg font-medium text-left text-gray-700 grow mb-2">
               Multi-Factor Authentication
            </h3>
            <button
               type="button"
               onClick={() => {
                  showModalSetupPage('app');
                  setShowMfaModal(true);
               }}
               className="inline-flex items-center px-3 py-2 mb-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
               Add App
            </button>
            {canAddNonPasskeyMethods && (
               <button
                  type="button"
                  onClick={() => {
                     setModalPage('offerPasskey');
                     setShowMfaModal(true);
                  }}
                  className="inline-flex items-center px-3 py-2 mb-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ml-2"
               >
                  Add Passkey
               </button>
            )}
         </div>
         <p className="text-gray-500">
            You have the following Multi-Factor Authentication methods set up:
         </p>
         <ul className="divide-y divide-gray-200 my-4">
            <li className="border-none p-4 flex bg-gray-100 m-2">
               <label className="flex items-center text-sm grow">
                  <span>{displayIcon['email']}</span>
                  <span className="ml-3 font-medium text-gray-900 basis-1/4">
                     Email
                  </span>
               </label>
               {!emailMfaMethod?.active || canDeleteNonPasskeyMethods ? (
                  <>
                     {/* <span className="ml-3 font-medium text-gray-500 basis-1/2">
                           Allow email Multi Factor Authentication?
                        </span> */}
                     <span>
                        <Toggle
                           value={emailMfaMethod?.id.toString()}
                           onChange={handleEmailActivateChange}
                           checked={emailMfaMethod?.active ?? false}
                        />
                     </span>
                  </>
               ) : null}
            </li>
            {nonEmailMfaMethods?.map(method => (
               <MfaMethodItem
                  key={method.id}
                  onDelete={deleteMethod}
                  canDeleteNonPasskeyMethods={canDeleteNonPasskeyMethods}
                  method={method}
               />
            ))}
         </ul>
         <p className="text-gray-500">
            Note that deleting a passkey or authenticator app will not remove it
            from your devices. Your device will still offer it, but it will not
            be accepted for login.
         </p>
      </div>
   );
}
interface MfaMethodItemProps {
   method: MfaMethod;
   onDelete: (methodId: number) => void;
   canDeleteNonPasskeyMethods: boolean;
}
function MfaMethodItem({
   method,
   onDelete,
   canDeleteNonPasskeyMethods,
}: MfaMethodItemProps) {
   return (
      <li className="border-none p-4 flex bg-gray-100 m-2">
         <label className="flex items-center text-sm grow">
            <span>{displayIcon[method.methodType]}</span>
            <span className="ml-3 font-medium text-gray-900 basis-1/4">
               {method.methodType}
            </span>
            <span className="ml-3 font-medium text-gray-500 basis-1/2">
               {method.identifier ? `${method.identifier} - ` : ''}
               {formatDateString(method.createdAt)}
            </span>
         </label>
         <span>
            {(method.methodType === 'passkey' ||
               canDeleteNonPasskeyMethods) && (
               <DeleteButton
                  onClick={() => onDelete(method.id)}
                  className="block p-3 mr-2 border border-gray-300 shadow-sm leading-4 font-medium rounded-md bg-white hover:bg-gray-50 focus:outline-none"
               />
            )}
         </span>
      </li>
   );
}
