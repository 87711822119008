import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalAdminInstance = new PublicClientApplication({
   auth: {
      clientId: 'c8249a22-ee8c-46e8-bfb4-bea700d8c834',
      authority:
         'https://login.microsoftonline.com/5442ad35-e3c2-4ad7-ad38-e30286b48083',
      redirectUri: window.location.origin + '/admin/login',
      navigateToLoginRequestUrl: false,
   },
   cache: {
      cacheLocation: 'localStorage',
   },

   system: {
      loggerOptions: {
         loggerCallback: (
            level: LogLevel,
            message: string,
            containsPii: boolean,
         ): void => {
            switch (level) {
               case LogLevel.Error:
                  console.error(message);
                  return;
               case LogLevel.Info:
                  console.info(message);
                  return;
               case LogLevel.Verbose:
                  console.debug(message);
                  return;
               case LogLevel.Warning:
                  console.warn(message);
                  return;
            }
         },
         piiLoggingEnabled: false,
      },
   },
});

export const msalInvestorInstance = new PublicClientApplication({
   auth: {
      clientId: '44dbffa1-959f-4763-868f-c10bebe7aa56',
      authority: 'https://login.microsoftonline.com/common/',
      redirectUri: window.location.origin + '/',
      navigateToLoginRequestUrl: false,
   },
   cache: {
      cacheLocation: 'localStorage',
   },
});

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
   scopes: ['User.Read'],
};
