import TimeDateChooser from '../../../components/TimeDateChooser';

interface Props {
   timeDate: Date | null;
   onChange: (timeDate: Date | null) => void;
   checked: boolean;
   title: string;
   displayPastTime?: boolean;
   pastTimeLabel?: string;
   onDepublish?: () => void;
   addedMinutesToDefault?: number;
   uncheckedMessage?: string;
}

function CheckboxTimeDate({
   timeDate,
   title,
   onChange,
   checked,
   pastTimeLabel,
   displayPastTime,
   onDepublish,
   addedMinutesToDefault,
   uncheckedMessage,
}: Props) {
   const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      let value: Date | null;

      if (checked) {
         value = addedMinutesToDefault
            ? new Date(new Date().getTime() + addedMinutesToDefault * 60000)
            : new Date();
      } else {
         value = null;
      }
      onChange(value);
   };

   const formatDate = (date: Date | null) => {
      if (!date) return '';

      const options: Intl.DateTimeFormatOptions = {
         year: 'numeric',
         month: 'long',
         day: 'numeric',
      };

      const d = new Date(date).toLocaleDateString(undefined, options);
      const t = new Date(date).toLocaleTimeString('en-IL');
      return `${d} ${t}`;
   };

   return (
      <div>
         {displayPastTime ? (
            <>
               {Boolean(onDepublish) && (
                  <button
                     type="button"
                     className="basic-btn"
                     onClick={onDepublish}
                  >
                     Depublish
                  </button>
               )}

               <span className="block my-2">
                  {pastTimeLabel}: {formatDate(timeDate)}{' '}
               </span>
            </>
         ) : (
            <>
               <label className="block">
                  <input
                     type="checkbox"
                     className="mr-2"
                     checked={checked}
                     onChange={handleCheckboxChange}
                  />
                  {title}
               </label>

               {checked ? (
                  <TimeDateChooser
                     timeDate={timeDate}
                     onChange={value => onChange(value)}
                  />
               ) : (
                  <>
                     {uncheckedMessage && (
                        <div className="bg-gray-300 p-4 w-max rounded-md mt-2">
                           {uncheckedMessage}
                        </div>
                     )}
                  </>
               )}
            </>
         )}
      </div>
   );
}

export default CheckboxTimeDate;
