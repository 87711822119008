import { forwardRef, Ref, useEffect, useRef, useState } from 'react';
import { FundManagerBatchGet } from 'types/interface';
import { formatReportingPeriod } from '../../../../../components/Batches/batch';
import Loader from '../../../../../components/Loader';
import useGet from '../../../../../hooks/useGet';

export type ReportingPeriodFormat = 'quarter' | 'month' | 'year';

export interface BatchFormValues {
   fileName?: string;
   requiresApproval: boolean;
   categoryId: number;
   reportingPeriod: string;
   reportingPeriodFormat: ReportingPeriodFormat;
}

interface Props {
   fundId: number;
   onSubmit: (formValues: BatchFormValues, filesAray?: File[]) => Promise<void>;
   initFormValues: BatchFormValues;
   displayFileUpload?: boolean;
   initFiles?: { folder: File[]; fileList: FileList };
}

function createBatchNameFromReportingPeriod(
   categoryName: string,
   reportingPeriodFormat: ReportingPeriodFormat,
   reportingPeriod: Date,
): string {
   const formattedReportingPeriod = formatReportingPeriod(
      reportingPeriodFormat,
      reportingPeriod,
   );

   return `${categoryName} - ${formattedReportingPeriod}`;
}

export default function BatchForm({
   fundId,
   onSubmit,
   initFormValues,
   displayFileUpload,
   initFiles,
}: Props) {
   const [fundManagers] = useGet(`fund/${fundId}/fund-managers`);
   const [categories] = useGet(`fund/${fundId}/fund-manager-batch/categories`);

   const fileInputRef = useRef<HTMLInputElement>(null);
   const fileItems = useRef<File[]>([]);

   useEffect(() => {
      if (initFiles) {
         if (fileItems.current.length === 0) {
            fileItems.current.push(...initFiles.folder);
         }
         if (fileInputRef.current) {
            fileInputRef.current.files = initFiles.fileList;
         }
      }
   }, [initFiles]);

   const [formValues, setFormValues] =
      useState<BatchFormValues>(initFormValues);
   const [uploading, setUploading] = useState(false);

   const categoryOptions: { id: number; name: string }[] = [
      { id: 0, name: 'Other' },
      ...(categories || []),
   ];

   useEffect(() => {
      if (
         formValues.reportingPeriod &&
         formValues.categoryId &&
         formValues.reportingPeriodFormat
      ) {
         const selectedCategory = categoryOptions.find(
            opt => opt.id === Number(formValues.categoryId),
         );
         if (selectedCategory) {
            const selectedDate = new Date(formValues.reportingPeriod);
            const batchName = createBatchNameFromReportingPeriod(
               selectedCategory.name,
               formValues.reportingPeriodFormat,
               selectedDate,
            );
            updateForm('fileName', batchName);
         }
      }
   }, [
      formValues.reportingPeriod,
      formValues.categoryId,
      formValues.reportingPeriodFormat,
   ]);

   const updateForm = (key: string, value: any) => {
      setFormValues({
         ...formValues,
         [key]: value,
      });
   };

   const handleChange = (
      e:
         | React.ChangeEvent<HTMLInputElement>
         | React.ChangeEvent<HTMLSelectElement>,
   ) => {
      updateForm(e.target.name, e.target.value);
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setUploading(true);
      try {
         await onSubmit(formValues, fileItems.current);
      } catch (err) {
         alert(err.message);
      } finally {
         setUploading(false);
      }
   };

   const handleDocumentUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;
      if (fileItems.current) {
         fileItems.current.push(...Array.from(e.target.files));
      }
   };

   const reportingPeriodFormats = ['year', 'month', 'quarter'];

   return (
      <div className="text-left">
         {displayFileUpload && (
            <div className="mb-4 border-black border p-2">
               {' '}
               <p>
                  Upload a folder (not zip) with documents to be sent to fund
                  managers on this fund, this will be avialable in the fund
                  manager portal.
               </p>
               {fundManagers && (
                  <p>
                     The following fund administrators will receive the uploaded
                     documents: {fundManagers.map(fm => fm.email).join(', ')}
                  </p>
               )}
            </div>
         )}

         <form onSubmit={handleSubmit} className="w-full">
            <label className="block mb-4">
               Category
               <select
                  className="form-select"
                  value={formValues.categoryId}
                  name="categoryId"
                  onChange={handleChange}
               >
                  {categoryOptions.map(category => (
                     <option key={category.id} value={category.id}>
                        {category.name}
                     </option>
                  ))}
               </select>
            </label>

            <label className="block mb-4">
               Reporting Period Format
               <select
                  className="form-select"
                  value={formValues.reportingPeriodFormat}
                  name="reportingPeriodFormat"
                  onChange={handleChange}
               >
                  {reportingPeriodFormats.map(format => (
                     <option value={format} key={format}>
                        {format.toLocaleUpperCase()}
                     </option>
                  ))}
               </select>
            </label>

            <label className="block mb-4">
               Reporting Period
               <input
                  className="form-input"
                  type="date"
                  value={formValues.reportingPeriod}
                  name="reportingPeriod"
                  onChange={handleChange}
               />
            </label>
            {displayFileUpload && (
               <input
                  ref={fileInputRef}
                  multiple
                  /* @ts-expect-error */
                  directory=""
                  webkitdirectory=""
                  mozdirectory=""
                  type="file"
                  className="w-full break-words"
                  onChange={handleDocumentUpload}
                  required
               />
            )}

            <label className="block">
               <input
                  type="checkbox"
                  className="mr-2 my-2"
                  onChange={e =>
                     updateForm('requiresApproval', e.target.checked)
                  }
                  checked={formValues.requiresApproval}
               />
               Requires Approval
            </label>

            <label className="block mb-4">
               Name
               <input
                  className="form-input"
                  type="text"
                  value={formValues.fileName}
                  name="fileName"
                  onChange={handleChange}
               />
            </label>

            {uploading ? (
               <Loader />
            ) : (
               <button className="form-btn bg-blue-500 block mx-auto mt-4">
                  {displayFileUpload ? 'Upload Batch' : 'Edit Batch'}
               </button>
            )}
         </form>
      </div>
   );
}
