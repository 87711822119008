import { useHistory } from 'react-router-dom';
import { FundManagerBatchDocumentGet } from 'types/interface';
import FileRow from '../../pages/Batch/FileRow';
import FolderRow from '../../pages/Batch/FolderRow';
import Table from '../Table';
import TableHeader from '../Table/TableHeader';
import TableRowHeader from '../Table/TableRowHeader';
import { folderAndFilesOfPath } from './batch';

interface Props {
   batch: FundManagerBatchDocumentGet;
   currentPath?: string;
   onDownloadDocument: (id: number) => void;
   onDeleteDocument?: (id: number) => void;
   basePath: string;
}

export function BatchFolderList({
   batch,
   currentPath,
   onDownloadDocument,
   onDeleteDocument,
   basePath,
}: Props) {
   const history = useHistory();

   const handleFolderClick = (name: string) => {
      let path: string;

      if (!currentPath) {
         path = `${location.pathname}/folder/${name}`;
      } else {
         let locationPathname = location.pathname.endsWith('/')
            ? location.pathname
            : `${location.pathname}/`;
         path = `${locationPathname}${name}`;
      }
      history.push(path);
   };

   const handleFileClick = (documentId: number, fileName: string) => {
      if (fileName.endsWith('.pdf')) {
         history.push(`${basePath}/${documentId}`);
      } else {
         onDownloadDocument(documentId);
      }
   };

   const folderAndFiles = folderAndFilesOfPath(
      currentPath ?? '',
      batch.fundManagerDocuments,
   );

   return (
      <Table>
         <TableHeader>
            <TableRowHeader>Name </TableRowHeader>
            <TableRowHeader>Actions </TableRowHeader>
         </TableHeader>
         <tbody>
            {folderAndFiles?.folders.map(folder => {
               return (
                  <FolderRow
                     key={folder}
                     name={folder}
                     onClick={() => handleFolderClick(folder)}
                  />
               );
            })}
            {folderAndFiles?.files.map(file => {
               return (
                  <FileRow
                     onDownload={() => onDownloadDocument(file.id)}
                     key={file.id}
                     batchId={batch.id}
                     id={file.id}
                     name={file.name}
                     onClick={() => handleFileClick(file.id, file.name)}
                     onDelete={
                        onDeleteDocument && (() => onDeleteDocument(file.id))
                     }
                  />
               );
            })}
         </tbody>
      </Table>
   );
}
