import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function AdminRestart() {
   const history = useHistory();

   useEffect(() => {
      window.localStorage.clear();
      window.sessionStorage.clear();
      history.push('/admin/login');
   }, []);

   return <></>;
}
