import { api } from '../../../lib/api';
import useGetNonTypeSafe from '../../../hooks/useGetNonTypeSafe';

import { useEffect, useState } from 'react';
import { DocTypeGet, DocumentGet } from 'types';
import { ReactSelectOption } from '../../../types';

import Select from 'react-select';
import CheckboxTimeDate from './CheckboxTimeDate';
import usePut from '../../../hooks/usePut';
import Banner from '../../../components/Banner';
import { formatInputDate } from '../../../lib/date';

const fallbackData: DocumentFormFormatted = {
   id: 0,
   fileName: '',
   documentType: 0,
   createdAt: new Date(),
   publishedAt: null,
   emailNotificationSent: false,
   emailNotificationAt: null,
   emailAttachment: false,
   isPublished: false,
   depublishedAt: null,
   fund: { id: 0, name: '', logo: '', deactivatedAt: null },
   documentDate: new Date(),
   bulk: null,
};

const formatDocumentDetails = (data: DocumentGet) => {
   return { ...data, documentType: data?.documentType?.id };
};

interface Props {
   documentId: number;
   fundId: number;
   accountId?: number;
   closeModal: () => void;
   refresh: () => void;
}

interface DocumentFormFormatted extends Omit<DocumentGet, 'documentType'> {
   documentType: number;
}

const formattedDocTypes = (data: DocTypeGet[]) =>
   data
      .map(docType => ({ value: docType.id, label: docType.name }))
      .concat([
         {
            value: 0,
            label: 'Other',
         },
      ]);

function EditDocument({
   documentId,
   fundId,
   accountId,
   closeModal,
   refresh,
}: Props) {
   const [initState, initStateError] = useGetNonTypeSafe<
      DocumentGet,
      DocumentFormFormatted
   >(
      `document/${documentId}?type=${accountId ? 'account' : 'fund'}`,
      formatDocumentDetails,
   );

   const [formValues, setFormValues] = useState(fallbackData);
   const [formError, setFormError] = useState();

   const [resendMessage, setResendMessage] = useState('');
   const [resendErrorMessage, setResendErrorMessage] = useState('');

   useEffect(() => {
      if (initState) {
         setFormValues(initState);
      }
   }, [initState]);

   const depublishDocument = () => {
      const confirmed = window.confirm(
         'Are you sure you would like to depublish this document?',
      );

      if (!confirmed) return;

      api.delete(`document/${documentId}`)
         .then(res => {
            closeModal();
            refresh();
         })
         .catch(err => setFormError(err));
   };

   const [docTypes, error] = useGetNonTypeSafe<
      DocTypeGet[],
      ReactSelectOption[]
   >(
      `document/types?level=${accountId ? 'account' : 'fund'}`,
      formattedDocTypes,
   );

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      updateForm(e.target.name, e.target.value);
   };

   const updateForm = (key: string, value: any) => {
      setFormValues({
         ...formValues,
         [key]: value,
      });
   };

   const handleEmailNotificationChange = (value: Date | null) => {
      const newValues = {
         ...formValues,
         emailNotificationAt: value,
      };
      //should not allow email attachment without an email notification time.
      if (value === null) {
         newValues.emailAttachment = false;
      }
      setFormValues(newValues);
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const isRepublish = Boolean(formValues.depublishedAt);
      let data = formValues;

      if (isRepublish) {
         const confirm = window.confirm(
            'Are you sure you would like to REPUBLISH this document?',
         );
         if (!confirm) return;
         data.depublishedAt = null;
      }
      try {
         await api.patch(`document/${documentId}`, data);
         closeModal();
         refresh();
      } catch (err) {
         setFormError(err);
      }
   };

   const resendNotificationEmail = async () => {
      try {
         await api.patch(`document/${documentId}`, {
            emailNotificationSent: false,
         });
         setResendMessage('Email notification will send shortly.');
      } catch (err) {
         setResendErrorMessage('Could not resend notification email.');
      }
   };

   return (
      <>
         {formValues && (
            <div className="text-left">
               {formError && (
                  <Banner type="error"> Oops, something went wrong! </Banner>
               )}
               {resendMessage && (
                  <Banner type="success"> {resendMessage} </Banner>
               )}
               {resendErrorMessage && (
                  <Banner type="error"> {resendErrorMessage} </Banner>
               )}
               <form onSubmit={handleSubmit}>
                  <div>File name</div>
                  <input
                     className="form-input"
                     type="text"
                     value={formValues.fileName}
                     name="fileName"
                     onChange={handleChange}
                  />

                  <div className="my-4 w-72">
                     <select
                        required
                        className="form-select"
                        onChange={e =>
                           updateForm('documentType', Number(e.target.value))
                        }
                        value={formValues.documentType ?? ''}
                     >
                        <option disabled value="">
                           Select Type
                        </option>
                        {docTypes?.map(d => (
                           <option value={d.value} key={d.value}>
                              {d.label}
                           </option>
                        ))}
                     </select>
                  </div>

                  <CheckboxTimeDate
                     timeDate={formValues.publishedAt}
                     onChange={value => updateForm('publishedAt', value)}
                     title="Published At"
                     checked={!!formValues.publishedAt}
                     displayPastTime={
                        formValues.isPublished && !formValues.depublishedAt
                     }
                     pastTimeLabel="Publish Time"
                     onDepublish={depublishDocument}
                  />

                  <CheckboxTimeDate
                     timeDate={formValues.emailNotificationAt}
                     onChange={handleEmailNotificationChange}
                     title="Email Notifcation At"
                     checked={!!formValues.emailNotificationAt}
                     displayPastTime={formValues.emailNotificationSent}
                     pastTimeLabel="Email Notification Time"
                  />

                  <div>
                     Document Date
                     <input
                        type="date"
                        required
                        onChange={e =>
                           updateForm('documentDate', e.target.value)
                        }
                        value={formatInputDate(formValues.documentDate)}
                        className="border-gray-300 border p-2 rounded-md mr-2 my-2 block"
                     />
                  </div>

                  <label className="block">
                     Email Attachment
                     <input
                        className="ml-2"
                        type="checkbox"
                        onChange={e =>
                           updateForm('emailAttachment', e.target.checked)
                        }
                        checked={formValues.emailAttachment}
                     />
                  </label>

                  {formValues.depublishedAt ? (
                     <button className="mt-4 block py-3 px-4 border border-gray-300 shadow-sm text-lg leading-4 font-medium rounded-md  bg-white hover:bg-gray-50 focus:outline-none text-red-600">
                        Republish
                     </button>
                  ) : (
                     <button className="basic-btn mt-4">Submit</button>
                  )}

                  {formValues.emailNotificationSent &&
                     !formValues.depublishedAt && (
                        <button
                           type="button"
                           onClick={resendNotificationEmail}
                           className="basic-btn mt-4"
                        >
                           Resend Email
                        </button>
                     )}
               </form>
            </div>
         )}
      </>
   );
}

export default EditDocument;
