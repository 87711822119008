import { useHistory } from 'react-router-dom';
import useSignout from '../hooks/useSignout';
import UserNavbar from './Navbar/UserNavbar';

interface Props {
   loggedInEmail: string;
   attemptedEmail: string;
}

export default function IncorrectUser(props: Props) {
   return (
      <div className="min-h-screen bg-gray-100">
         <UserNavbar />
         <div className="mx-4 py-20 md:py-24">
            <IncorrectUserModal {...props} />
         </div>
      </div>
   );
}

function IncorrectUserModal({ loggedInEmail, attemptedEmail }: Props) {
   const signout = useSignout();
   const history = useHistory();

   const handleCancel = () => {
      history.push('/');
   };

   return (
      <div
         id="popup-modal"
         tabIndex={-1}
         className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full"
      >
         <div className="relative p-4 w-fit mx-auto top-20  h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
               <div className="p-6 text-center">
                  <h3 className=" max-w-sm mb-5 text-lg font-normal text-gray-500 dark:text-gray-400 lg:max-w-xl ">
                     <p className=" break-words">
                        {' '}
                        You clicked a link intended for <b>{attemptedEmail}</b>,
                        but you are logged in as <b>{loggedInEmail}</b>.
                     </p>
                  </h3>
                  <div>
                     <button
                        onClick={signout}
                        data-modal-toggle="popup-modal"
                        type="button"
                        className=" text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                     >
                        Logout and continue as {attemptedEmail}
                     </button>
                     <button
                        onClick={handleCancel}
                        data-modal-toggle="popup-modal"
                        type="button"
                        className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                     >
                        Cancel
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
