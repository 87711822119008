import { useEffect, useState } from 'react';

import { get } from '../lib/api';
import type { GetResponse, ApiMethodPath } from 'types/index2';

export default function useGet<P extends ApiMethodPath<'GET'>>(
   path: P,
): [GetResponse<P> | undefined, string | undefined, boolean, () => void] {
   const [value, setValue] = useState<GetResponse<P>>();
   const [errorMessage, setErrorMessage] = useState<string>();
   const [loading, setLoading] = useState(Boolean(path));

   const [counter, setCounter] = useState(0);

   useEffect(() => {
      if (path === null) return;
      let cancelled = false;

      const getDataFromApi = async () => {
         try {
            setLoading(true);
            const res = await get<P>(path);

            if (!cancelled) {
               setValue(res);
               setLoading(false);
            }
         } catch (err) {
            if (!cancelled) {
               err instanceof Error
                  ? setErrorMessage(err.message)
                  : setErrorMessage(err);
               setLoading(false);
            }
         }
      };

      getDataFromApi();
      return () => {
         cancelled = true;
         setLoading(false);
      };
   }, [path, counter]);

   const refresh = () => setCounter(counter => counter + 1);

   return [value, errorMessage, loading, refresh];
}
