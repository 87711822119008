import { useEffect, useState } from 'react';
import Banner from '../components/Banner';
import UserNavbar from '../components/Navbar/UserNavbar';
import usePost from '../hooks/usePost';

const initalData = { msg: '', subject: '' };
function Contact() {
   const [formData, setFormData] = useState(initalData);

   const [sendData, postValue, postErrorMessage] = usePost<{}>('me/contact');

   useEffect(() => {
      setFormData(initalData);
   }, [postValue]);

   const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
   ) => {
      setFormData({
         ...formData,
         [e.target.name]: e.target.value,
      });
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      sendData(formData);
   };

   return (
      <div className="min-h-screen bg-gray-100">
         <UserNavbar />

         {postValue && (
            <Banner type="success">
               Thank you for your message. We will be in touch shortly.
            </Banner>
         )}
         {postErrorMessage && (
            <Banner type="error">
               We could not send your message at the moment.
            </Banner>
         )}

         <div className="mx-4 py-20 md:py-24">
            <div className="max-w-xl mx-auto">
               <div className="pb-3 md:pb-5 sm:flex md:items-center md:justify-between">
                  <h1 className="text-3xl font-bold leading-tight text-gray-900">
                     Contact Us
                  </h1>
               </div>

               <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="p-4 md:p-6">
                     {/* <!-- START CONTACT FORM --> */}

                     <form onSubmit={handleSubmit}>
                        <div className="col-span-6 mb-4">
                           <label
                              htmlFor="last_name"
                              className="mb-2 block text-sm font-medium text-gray-700"
                           >
                              Subject
                           </label>
                           <input
                              onChange={handleChange}
                              value={formData.subject}
                              type="text"
                              name="subject"
                              id="subject"
                              autoComplete="subject"
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              placeholder="Subject"
                           />
                        </div>

                        <div className="col-span-6 mb-4">
                           <label
                              htmlFor="contact-message"
                              className="block text-sm font-medium text-gray-700"
                           >
                              Message
                           </label>

                           <textarea
                              onChange={handleChange}
                              value={formData.msg}
                              id="contact-message"
                              name="msg"
                              rows={3}
                              className="mt-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              placeholder="Write your message"
                           ></textarea>
                        </div>

                        <div className="mt-3 flex col-span-6">
                           <button
                              type="submit"
                              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
                           >
                              Send Message
                           </button>
                        </div>
                     </form>

                     <p className="my-2">
                        Or send us an email at
                        <a
                           href={`mailto:${process.env.REACT_APP_INVESTOR_SERVICES_EMAIL}`}
                           className="mx-1 text-blue-600"
                        >
                           {process.env.REACT_APP_INVESTOR_SERVICES_EMAIL}
                        </a>
                     </p>

                     {/* <!-- END CONTACT FORM --> */}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Contact;
