import { useState } from 'react';
import CheckboxTimeDate from './CheckboxTimeDate';

export interface BulkForm {
   publishedAt: null | Date;
   emailNotificationAt: null | Date;
   emailAttachment: boolean;
}

const INITAL_FORM_VALUES: BulkForm = {
   publishedAt: new Date(),
   emailNotificationAt: null,
   emailAttachment: false,
};

interface Props {
   onSubmit: (data: BulkForm) => void;
}

function BulkPublishDocument({ onSubmit }: Props) {
   const [formValues, setFormValues] = useState(INITAL_FORM_VALUES);

   const updateForm = (key: string, value: any) => {
      setFormValues({
         ...formValues,
         [key]: value,
      });
   };

   const handleEmailNotificationChange = (value: Date | null) => {
      const newValues = {
         ...formValues,
         emailNotificationAt: value,
      };
      //should not allow email attachment without an email notification time.
      if (value === null) {
         newValues.emailAttachment = false;
      }
      setFormValues(newValues);
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit(formValues);
   };

   return (
      <div className="text-left">
         <form onSubmit={handleSubmit}>
            <CheckboxTimeDate
               timeDate={formValues.publishedAt}
               onChange={value => updateForm('publishedAt', value)}
               title="Set publish time"
               checked={!!formValues.publishedAt}
               uncheckedMessage="Document will not be visible until published"
            />

            <CheckboxTimeDate
               timeDate={formValues.emailNotificationAt || null}
               onChange={handleEmailNotificationChange}
               title="Schedule email notification"
               checked={!!formValues.emailNotificationAt}
               addedMinutesToDefault={30}
               uncheckedMessage="No email notification will be sent for this document"
            />

            {!!formValues.emailNotificationAt && (
               <label className="block">
                  <input
                     type="checkbox"
                     className="mr-2"
                     onChange={e =>
                        updateForm('emailAttachment', e.target.checked)
                     }
                     checked={formValues.emailAttachment}
                  />
                  Email Attachment
               </label>
            )}

            <button className="form-btn bg-blue-500 block mx-auto mt-4">
               Bulk Publish
            </button>
         </form>
      </div>
   );
}

export default BulkPublishDocument;
