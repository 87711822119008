import copy from 'copy-to-clipboard';

export default function CopyTextButton({ text }: { text: string }) {
   const copyText = () => {
      const copied = copy(text);
      if (copied) {
         alert('Copied to clipboard');
      } else {
         alert('Failed to copy to clipboard');
      }
   };

   return (
      <>
         <button
            className="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-800
              bg-gray-200 border border-transparent rounded-md hover:bg-blue-200 
              focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            onClick={copyText}
         >
            Copy
         </button>
      </>
   );
}
