import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
   onClick: () => void;
   textColor?: string;
   className?: string;
}
export default function Button({
   onClick,
   textColor,
   children,
   className,
}: Props) {
   textColor = textColor ?? 'text-gray-700';
   return (
      <button
         onClick={onClick}
         className={`block p-2 border border-gray-300 shadow-sm  leading-4 font-medium rounded-md bg-white hover:bg-gray-50 focus:outline-none ${textColor} ${
            className || ''
         }`}
      >
         {children}
      </button>
   );
}
