import { Link } from 'react-router-dom';

interface Props {
   items: { [x: string]: string };
}
export default function Breadcrumbs({ items }: Props) {
   return (
      <nav className="bg-grey-light rounded font-sans w-full">
         <ol className="list-reset flex gap-4 text-grey-dark">
            {Object.entries(items).map(([text, link], i) => (
               <li
                  key={text}
                  className="mr pl-2"
                  style={{ listStyleType: i === 0 ? 'none' : "'>'" }}
               >
                  {link ? (
                     <Link className="text-blue-400 hover:underline" to={link}>
                        {text}
                     </Link>
                  ) : (
                     text
                  )}
               </li>
            ))}
            <li className="mr pl-2" style={{ listStyleType: "'>'" }} />
         </ol>
      </nav>
   );
}

export function AdminBreadcrumbs({ items = {} }: Props) {
   const newItems = Object.entries(items);
   newItems.unshift(['Admin home', '/admin']);
   return <Breadcrumbs items={Object.fromEntries(newItems)} />;
}
