import { useState } from 'react';
import { MfaValidateMethodProps } from '../MfaValidate';
import { mfaApiLogin } from '../../../lib/api';

export default function MfaValidateApp({
   selectedMethodInfo,
   onSuccess,
}: MfaValidateMethodProps) {
   const [error, setError] = useState('');
   const [codeToVerify, setCodeToVerify] = useState('');

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCodeToVerify(e.target.value);
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!codeToVerify) {
         return setError('Please enter code');
      }

      mfaApiLogin('post', 'mfa/app', {
         mfaAuthId: selectedMethodInfo.id,
         code: codeToVerify,
      })
         .then(() => onSuccess())
         .catch(err =>
            setError(err.message ?? `The code did not match our records.`),
         );
   };

   return (
      selectedMethodInfo && (
         <>
            <h2 className="mb-1 text-left text-3xl font-extrabold text-gray-900">
               App authentication
            </h2>
            <h3 className="mb-4 text-left text-medium font-bold text-gray-900">
               {selectedMethodInfo.identifier}
            </h3>
            {error ? (
               <div className="bg-red-200 p-4 rounded-md text-left my-2">
                  {error}
               </div>
            ) : null}

            <p className="mb-4 font-regular text-gray-600">
               Enter the 6-digit code you get from the app.
            </p>

            <form className="space-y-6" onSubmit={handleSubmit}>
               <div>
                  <label
                     htmlFor="verification-code"
                     className="block text-sm font-medium text-gray-700"
                  >
                     Verification Code
                  </label>
                  <div className="mt-1">
                     <input
                        value={codeToVerify}
                        onChange={handleChange}
                        id="phone"
                        name="phone"
                        type="text"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                     />
                  </div>
               </div>
               <div>
                  <button
                     disabled={codeToVerify.length !== 6}
                     type="submit"
                     className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-300"
                  >
                     Verify
                  </button>
               </div>
            </form>
         </>
      )
   );
}
