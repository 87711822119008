import { useState } from 'react';

import { api } from '../lib/api';

function useSend<T, Req = T>(
   method: 'post' | 'put',
   path: string,
): [(body: Req) => Promise<T>, T | null, Error | null] {
   const [value, setValue] = useState<T | null>(null);
   const [errorMessage, setErrorMessage] = useState<Error | null>(null);

   const sendData = async (body: any) => {
      setErrorMessage(null);
      setValue(null);

      try {
         const res = await api[method]<T>(path, body);
         setValue(res);
         return res;
      } catch (err) {
         setErrorMessage(err);
         throw err;
      }
   };

   return [sendData, value, errorMessage];
}

export default useSend;
