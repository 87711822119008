import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormModal from '../FormModal';
import RequestDownloads from '../RequestDownloads';

interface Props {
   submitted: boolean;
   completedAt: Date | null;
   requestId: number;
   onBackToEdit: () => void;
   onConfirmSubmit: () => void;
   isFinalSignator: boolean;
   secondSignatureAssigned: boolean;
   onSendToSecondSignator: (email: string) => void;
}

export default function CompletedSurvey({
   submitted,
   completedAt,
   requestId,
   onBackToEdit,
   onConfirmSubmit,
   isFinalSignator,
   secondSignatureAssigned,
   onSendToSecondSignator,
}: Props) {
   const [displaySendToInvestorModal, setDisplaySendToInvestorModal] =
      useState(false);

   const { t, i18n } = useTranslation('survey');

   const handleConfirmSubmit = () => {
      window.confirm(
         t(
            'Are you sure you wish to submit? You will not be able to edit the answers any more.',
         )!,
      );
      onConfirmSubmit();
   };

   const handleSendToInvestor = (email: string) => {
      onSendToSecondSignator(email);
      setDisplaySendToInvestorModal(false);
   };

   const displayRequestSentToInvestorBanner =
      !isFinalSignator && secondSignatureAssigned;

   return (
      <div className="max-w-5xl mx-auto mb-16">
         <FormModal
            title="Send for Approval"
            onClose={() => setDisplaySendToInvestorModal(false)}
            display={displaySendToInvestorModal}
         >
            <SendToInvestorModal onSubmit={handleSendToInvestor} />
         </FormModal>

         {submitted ? (
            <div className="bg-emerald-100 text-center text-lg border-emerald-700 text-emerald-700 shadow-md p-3 mb-6">
               {t('This form was submitted on')}{' '}
               {new Date(completedAt!).toLocaleDateString()}
            </div>
         ) : (
            <>
               <div className="bg-amber-100 text-center text-lg border-amber-700 text-amber-700 shadow-md p-6 mb-6">
                  {t(
                     'Please check the generated documents below. ' +
                        'If everything is correct, press Submit to send them. ' +
                        'You will not be able to edit the data after submitting.',
                  )}
               </div>

               {displayRequestSentToInvestorBanner && (
                  <div className="bg-green-600 text-center text-lg border-white text-white shadow-md p-6 mb-6">
                     {t(`This form has been sent and is awaiting approval.`)}
                  </div>
               )}
            </>
         )}
         <RequestDownloads requestId={requestId} />
         {!submitted && (
            <div className="bg-white border-gray-500 rounded-lg shadow-inner p-3 my-3 flex flex-row gap-3">
               <button className="basic-btn" onClick={onBackToEdit}>
                  {t('Edit answers')}
               </button>

               {isFinalSignator ? (
                  <button className="edit-btn" onClick={handleConfirmSubmit}>
                     {[t('Submit')]}
                  </button>
               ) : (
                  <>
                     {secondSignatureAssigned ? null : (
                        <button
                           className="edit-btn"
                           onClick={() => setDisplaySendToInvestorModal(true)}
                        >
                           {t('Send for Approval')}
                        </button>
                     )}
                  </>
               )}
            </div>
         )}
      </div>
   );
}

interface SendToInvestorModalProps {
   onSubmit: (email: string) => void;
}

function SendToInvestorModal({ onSubmit }: SendToInvestorModalProps) {
   const [email, setEmail] = useState('');

   const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit(email);
   };

   return (
      <form onSubmit={handleSubmit}>
         <label
            htmlFor="email_address"
            className="mt-4 mb-2 block text-sm font-medium text-gray-700 text-left"
         >
            Investor's email address
            <input
               type="email"
               className="form-input"
               value={email}
               onChange={e => setEmail(e.target.value)}
               required
            />
         </label>
         <button className="basic-btn mt-4">Submit</button>
      </form>
   );
}
