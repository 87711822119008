import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { removeAuthToken } from '../lib/api';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';

export default function useSignout() {
   const msal = useMsal()?.instance;
   const isAuthenticated = useIsAuthenticated();
   const account = useAccount();
   const { handleUserLogout } = useContext(UserContext);

   return () => {
      removeAuthToken();
      if (isAuthenticated) {
         msal.logoutRedirect({
            account,
            onRedirectNavigate: () => false,
         });
      }
      handleUserLogout();
   };
}
