import { useEffect, useRef, useState } from 'react';
import { MfaValidateMethodProps } from '../MfaValidate';
import { userLogin } from '../../../lib/passkey';
import Button from '../../Button';

export default function MfaValidatePasskey({
   onSuccess,
}: MfaValidateMethodProps) {
   const [cancelled, setCancelled] = useState(false);

   const authenticate = (signal?: AbortSignal) => {
      setCancelled(false);
      userLogin(signal).then(onSuccess, () => setCancelled(true));
   };

   useEffect(() => {
      const abortController = new AbortController();
      authenticate(abortController.signal);
      return () => abortController.abort();
   }, []);

   return (
      <>
         <h2 className="mb-6 text-left text-2xl font-extrabold text-gray-900">
            Passkey authentication
         </h2>
         <p>Follow the prompts from your browser.</p>
         {cancelled && (
            <p>
               <Button
                  className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => authenticate()}
               >
                  Try again
               </Button>
            </p>
         )}
      </>
   );
}
