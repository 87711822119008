import { useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useGet from '../../hooks/useGet';
import { patch, post, remove } from '../../lib/api';
import AddFilesModal from '../../pages/admin/fund/FundManagerDocs/Batch/AddFilesModal';
import BatchForm, {
   BatchFormValues,
} from '../../pages/admin/fund/FundManagerDocs/Batch/BatchForm';
import Banner from '../Banner';
import FileDragAndDrop from '../FileDragAndDrop';
import FormModal from '../FormModal';
import BatchFolder, { BatchFolderProps } from './BatchFolder';

export interface BatchAdminFolderProps extends BatchFolderProps {
   onBatchUpdated?: () => void;
}
export default function BatchAdminFolder({
   onBatchUpdated,
   ...props
}: BatchAdminFolderProps) {
   const match = useRouteMatch<{
      currentPath?: string;
      fundId: string;
      batchId: string;
   }>();
   const history = useHistory();
   const { currentPath, fundId } = match.params;

   const [fundManagers] = useGet(`fund/${Number(fundId)}/fund-managers`);

   const [inEditMode, setInEditMode] = useState(false);
   const [displayAddFilesModal, setDisplayAddFilesModal] = useState(false);
   const [displayEditBatchModal, setDisplayEditBatchModal] = useState(false);
   const [uploadingFiles, setUploadingFiles] = useState(false);
   const [duplicateFileNames, setDuplicateFileNames] = useState<string[]>();
   const [fileItems, setFileItems] = useState<{
      folder: File[];
      fileList: FileList;
   }>();

   const [resendNotificationMessage, setResendNotificationMessage] =
      useState('');

   const { batch } = props;

   const handleDeleteBatch = async () => {
      const confirmation = confirm(
         'Are you sure you would like to archive this batch?',
      );
      if (!confirmation) {
         return;
      }
      try {
         await remove(`fund/${Number(fundId)}/fund-manager-batch/${batch.id}`);
         history.push(`/admin/funds/${Number(fundId)}/fund-manager-documents`);
      } catch (err) {
         alert(err.message);
      }
   };

   const handleAddFilesToBatch = async (files: FileList | File[]) => {
      setDuplicateFileNames(undefined);
      const formData = new FormData();

      let filesArray: File[] = [];

      if (files instanceof FileList) {
         filesArray = Array.from(files);
      } else {
         filesArray = files;
      }

      const duplicateNames: string[] = [];
      filesArray.forEach(file => {
         let fileName = file.webkitRelativePath || file.name;
         if (currentPath) {
            fileName = `${currentPath}/${fileName}`;
         }
         //check name doesn't already exist
         if (
            props.batch.fundManagerDocuments.find(
               fmDoc => fmDoc.name === fileName,
            )
         ) {
            duplicateNames.push(fileName);
         } else {
            formData.append(fileName, file);
         }
      });

      if (duplicateNames.length) {
         setDuplicateFileNames(duplicateNames);
         return;
      }

      try {
         setUploadingFiles(true);
         await post(
            `fund/${Number(fundId)}/fund-manager-batch/${batch.id}`,
            formData,
         );
         setInEditMode(false);
         setDisplayAddFilesModal(false);
         onBatchUpdated?.();
      } catch (err) {
         alert(err.message);
      } finally {
         setUploadingFiles(false);
      }
   };

   const handleDeleteFileFromBatch = async (fileId: number) => {
      const confirmation = confirm(
         'Are you sure you would like to delete this file?',
      );
      if (!confirmation) {
         return;
      }

      try {
         await remove(
            `fund/${Number(fundId)}/fund-manager-batch/${Number(
               batch.id,
            )}/document/${fileId}`,
         );
         onBatchUpdated?.();
      } catch (err) {
         alert(err.message);
      }
   };

   const handleEditBatch = async (data: BatchFormValues) => {
      try {
         await patch(
            `fund/${Number(fundId)}/fund-manager-batch/${batch.id}`,
            data,
         );
         setInEditMode(false);
         setDisplayEditBatchModal(false);
         onBatchUpdated?.();
      } catch (err) {
         alert(err.message);
      }
   };

   const fundManagerEmails = fundManagers?.map(fm => fm.email) || [];

   const handleBatchNotification = async () => {
      const confirmation = confirm(
         `Are you sure you would like to resend a notification to: ${fundManagerEmails.join(
            ', ',
         )}?`,
      );
      if (!confirmation) {
         return;
      }
      try {
         await post(
            `fund/${Number(fundId)}/fund-manager-batch/${Number(
               batch.id,
            )}/notify`,
            null,
         );
         setResendNotificationMessage(`Batch notfication resent`);
      } catch (err) {
         alert(err.message);
      }
   };

   const handleFileUpload = (fileList: FileList) => {
      setFileItems({ folder: [], fileList });
      setDisplayAddFilesModal(true);
   };

   const handleFolderUpload = (folder: File[], fileList: FileList) => {
      setFileItems({ folder, fileList });
      setDisplayAddFilesModal(true);
   };

   const handleCloseAddFilesModal = () => {
      setDisplayAddFilesModal(false);
      setDuplicateFileNames(undefined);
      setFileItems(undefined);
   };

   return (
      <>
         {resendNotificationMessage && (
            <Banner type="success"> {resendNotificationMessage}</Banner>
         )}
         <FileDragAndDrop
            disabled={!inEditMode}
            onUpload={handleFileUpload}
            acceptFolder
            onFolderUpload={handleFolderUpload}
         >
            {displayAddFilesModal && (
               <FormModal
                  title="Edit Batch"
                  display={displayAddFilesModal}
                  onClose={handleCloseAddFilesModal}
               >
                  <AddFilesModal
                     onSubmit={handleAddFilesToBatch}
                     isUploading={uploadingFiles}
                     duplicateFileNames={duplicateFileNames}
                     initFiles={fileItems}
                  />
               </FormModal>
            )}

            {displayEditBatchModal && (
               <FormModal
                  title="Edit Batch"
                  display={displayEditBatchModal}
                  onClose={() => setDisplayEditBatchModal(false)}
               >
                  <BatchForm
                     initFormValues={{
                        ...batch,
                        fileName: batch.name,
                        categoryId: Number(batch.category?.id),
                        reportingPeriod: String(batch.reportingPeriod),
                     }}
                     fundId={Number(fundId)}
                     onSubmit={handleEditBatch}
                  />
               </FormModal>
            )}

            <BatchFolder
               {...props}
               fundManagerEmails={fundManagerEmails}
               onDeleteBatch={handleDeleteBatch}
               onEditBatch={
                  !batch.approvedAt
                     ? () => setInEditMode(prevEditMode => !prevEditMode)
                     : undefined
               }
               onAddFiles={() => setDisplayAddFilesModal(true)}
               onSendNotificationBatch={handleBatchNotification}
               isEditMode={inEditMode}
               onDeleteFile={inEditMode ? handleDeleteFileFromBatch : undefined}
               onEditBatchInfo={
                  inEditMode ? () => setDisplayEditBatchModal(true) : undefined
               }
            />
         </FileDragAndDrop>
      </>
   );
}
