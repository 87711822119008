import { api, get } from '../../lib/api';
import { downloadDocumentFromPath } from '../../lib/file';

export type BaseUrl = 'files/batches' | `fund/${number}/fund-manager-batch`;

export function handleDownloadBatch(baseUrl: BaseUrl, batchId: number) {
   return api.download(`${baseUrl}/${batchId}/download`);
}

export const getDocumentFromApi = (
   baseUrl: BaseUrl,
   documentId: number,
   batchId: number,
) => {
   return get(`${baseUrl}/${Number(batchId)}/document/${documentId}/download`);
};

export const handleDownloadDocument = async (
   baseUrl: BaseUrl,
   documentId: number,
   batchId: number,
) => {
   const documentPath = await getDocumentFromApi(baseUrl, documentId, batchId);
   downloadDocumentFromPath(documentPath);
};
