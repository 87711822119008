import { Menu, Transition } from '@headlessui/react';

interface Props {
   title: string;
   children: React.ReactNode;
}

//title, children

function Dropdown({ title, children }: Props) {
   return (
      <div className="relative">
         <Menu>
            {({ open }) => (
               <>
                  <span className="rounded-md shadow-sm">
                     <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800">
                        <span>{title}</span>
                        <svg
                           className="w-5 h-5 ml-2 -mr-1"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                        >
                           <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                           />
                        </svg>
                     </Menu.Button>
                  </span>

                  <Transition
                     show={open}
                     enter="transition ease-out duration-100"
                     enterFrom="opacity-0 scale-95"
                     enterTo="opacity-100 scale-100"
                     leave="transition ease-in duration-75"
                     leaveFrom="opacity-100 scale-100"
                     leaveTo="opacity-0 scale-95"
                  >
                     {children}
                  </Transition>
               </>
            )}
         </Menu>
      </div>
   );
}

export default Dropdown;
