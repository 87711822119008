import {
   InteractionType,
   InteractionStatus,
   SilentRequest,
   RedirectRequest,
   InteractionRequiredAuthError,
} from '@azure/msal-browser';
import {
   useAccount,
   useIsAuthenticated,
   useMsal,
   useMsalAuthentication,
} from '@azure/msal-react';
import { useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext, isAdminToken } from '../../contexts/UserContext';
import { adminAzureLogin } from '../../lib/api';
import type { RedirectLocationState } from '../../routes/ProtectedRoute';

export default function AdminLogin() {
   const { user, handleUserLogin } = useContext(UserContext);

   const history = useHistory();
   const location = useLocation<RedirectLocationState>();

   const redirectTo: string = location?.state?.from
      ? (location.state.from.pathname || '') +
        (location.state.from.search || '')
      : '/admin';

   const silentLoginRequest: SilentRequest = {
      scopes: ['user.read', 'openid', 'profile'],
      redirectUri: '/admin/login',
   };
   const redirectLoginRequest: RedirectRequest = {
      ...silentLoginRequest,
      state: redirectTo,
      prompt: 'select_account',
   };

   // This will only perform a login if we are not already logged in to MSAL
   const { result, error, login } = useMsalAuthentication(
      InteractionType.Redirect,
      redirectLoginRequest,
   );
   // Need the instance for logging out on unsuccesful login
   // Need inProgress for knowing when we need to do it manually
   const { instance, inProgress, accounts } = useMsal();
   const isAuthenticated = useIsAuthenticated();
   const account = useAccount();

   // Logged in but without an active account (shouldn't happen, might happen from an old cache)
   useEffect(() => {
      if (
         !user &&
         inProgress === InteractionStatus.None &&
         isAuthenticated &&
         !account &&
         !result &&
         !error
      ) {
         instance.setActiveAccount(accounts[0]);
      }
   });

   // Handle valid login
   useEffect(() => {
      if (!user && result) {
         instance.setActiveAccount(result.account);
         adminAzureLogin(result.idToken)
            .then(handleUserLogin)
            .catch(err => {
               console.error('Admin login error', err);
               instance.logoutRedirect({ onRedirectNavigate: () => false });
               history.push('/forbidden');
            });
      }
   }, [result, user]);

   // On login error, use redirect
   // This is probably no longer needed now that redirect is the primary login method
   useEffect(() => {
      if (!user && error instanceof InteractionRequiredAuthError) {
         console.error('MSAL automatic login error', error);
         login(InteractionType.Redirect, redirectLoginRequest);
      } else if (error) {
         console.error('Unknown MSAL error', error, { user, account });
      }
   }, [error, user]);

   // Once we have a user, send them to the right place
   // This will also work if the user is already logged in when they hit this page
   useEffect(() => {
      if (user) {
         if (!isAdminToken(user)) {
            history.push('/forbidden');
         } else {
            history.push(result?.state || redirectTo);
         }
      }
   }, [user]);

   // Failsafe - if you get stuck on this page, clear storage and reload
   useEffect(() => {
      const timeout = setTimeout(() => {
         // Send it to logs
         console.error('Admin login got stuck', { user, account, inProgress });

         window.localStorage.clear();
         window.sessionStorage.clear();
         document.location.reload();
      }, 15 * 1000);

      return () => clearTimeout(timeout);
   }, [user, account, inProgress]); // Restart the clock when these change

   return null;
}
