import LoggedOutLayout from '../components/LoggedOutLayout';
import { useContext, useEffect, useState } from 'react';
import { checkPasswordResetToken, setNewPassword, userLogin } from '../lib/api';
import Banner from '../components/Banner';
import { RouteComponentProps, useHistory, Link } from 'react-router-dom';

import { isUserToken, UserContext } from '../contexts/UserContext';
import ThirdPartyLogin from '../components/ThirdPartyLogin';
import { LocationDescriptor } from 'history';
import { RedirectLocationState } from '../routes/ProtectedRoute';
import IncorrectUser from '../components/IncorrectUser';

const initalFormData = { password: '', confirmPassword: '' };

function ResetPassword({
   location,
   history,
}: RouteComponentProps<{}, {}, RedirectLocationState>) {
   const urlParams = new URLSearchParams(location.search);
   const firstTimeLogin = urlParams.has('first-time');
   const resetToken = urlParams.get('token') ?? '';
   const displayEmail = urlParams.get('u');

   const { user, handleUserLogin } = useContext(UserContext);

   const redirect: LocationDescriptor = location.state?.from?.pathname || '/';

   const [userEmail, setUserEmail] = useState('');
   const [emailError, setEmailError] = useState('');

   useEffect(() => {
      if (resetToken) {
         checkPasswordResetToken(resetToken)
            .then(res => setUserEmail(res as string))
            .catch(err => setEmailError(err.message));
      }
   }, [resetToken]);

   const [formData, setFormData] = useState(initalFormData);

   const [resetPasswordError, setResetPasswordError] = useState('');

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({
         ...formData,
         [e.target.name]: e.target.value,
      });
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const { password, confirmPassword } = formData;

      if (password !== confirmPassword) {
         //TODO: Replace with proper UI --> Banner
         alert(`Passwords do not match.`);
         return;
      }

      try {
         if (!userEmail) return;
         await setNewPassword(resetToken, { password });
         const res = await userLogin({ email: userEmail, password });
         await handleUserLogin();
         history.push(redirect);
      } catch (err) {
         setResetPasswordError('Could not set new password at this moment.');
      }
   };

   if (
      isUserToken(user) &&
      user.email &&
      displayEmail &&
      displayEmail !== user.email
   ) {
      return (
         <IncorrectUser
            loggedInEmail={user.email}
            attemptedEmail={displayEmail}
         />
      );
   }

   return (
      <LoggedOutLayout>
         {resetPasswordError && (
            <Banner type="error">{resetPasswordError}</Banner>
         )}
         {emailError ? (
            <div>
               <p className="my-4">The reset password link has expired.</p>
               <p className="my-4">
                  You can request a new one{' '}
                  <Link className="link" to="/forgot-password">
                     here
                  </Link>
                  .
               </p>
               <p className="my-4">
                  If you continue to experience issues, please contact Investor
                  Services at{' '}
                  <a
                     className="link"
                     href={`mailto:${process.env.REACT_APP_INVESTOR_SERVICES_EMAIL}`}
                  >
                     {process.env.REACT_APP_INVESTOR_SERVICES_EMAIL}
                  </a>
                  .
               </p>
            </div>
         ) : (
            <>
               <h2 className="mb-6 text-left text-3xl font-extrabold text-gray-900">
                  Create a password
               </h2>
               <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                     <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 text-left"
                     >
                        Your email address
                     </label>
                     <div className="mt-1">
                        <input
                           type="text"
                           disabled
                           value={
                              typeof userEmail === 'string' ? userEmail : ''
                           }
                           className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md  placeholder-gray-400 
              focus:outline-none sm:text-sm"
                        />
                     </div>
                  </div>
                  <div>
                     <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700 text-left"
                     >
                        Password{' '}
                        <span className="font-normal text-gray-500">
                           (at least 8 characters)
                        </span>
                     </label>
                     <div className="mt-1">
                        <input
                           minLength={8}
                           onChange={handleChange}
                           value={formData.password}
                           id="password"
                           name="password"
                           type="password"
                           autoComplete="current-password"
                           required
                           className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                     </div>
                  </div>
                  <div>
                     <label
                        htmlFor="confirmPassword"
                        className="block text-sm font-medium text-gray-700 text-left"
                     >
                        Confirm Password
                     </label>
                     <div className="mt-1">
                        <input
                           minLength={8}
                           onChange={handleChange}
                           value={formData.confirmPassword}
                           id="confirmPassword"
                           name="confirmPassword"
                           type="password"
                           autoComplete="current-password"
                           required
                           className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                     </div>
                  </div>
                  <div>
                     <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                     >
                        Submit
                     </button>
                  </div>
               </form>
               {firstTimeLogin && (
                  <div className="mt-5">
                     <ThirdPartyLogin
                        onSuccess={() =>
                           handleUserLogin().then(() => history.push(redirect))
                        }
                        onError={setResetPasswordError}
                     />
                  </div>
               )}
            </>
         )}
      </LoggedOutLayout>
   );
}

export default ResetPassword;
