import UserNavbar from '../../components/Navbar/UserNavbar';
import RequestsTable from '../../components/RequestsTable';

function Requests() {
   return (
      <div className="min-h-screen bg-gray-100">
         <UserNavbar />
         <RequestsTable
            baseFundUrl="me/managed-funds"
            managedFundsUrl="me/managed-funds"
            requestsBaseUrl="me/requests"
         />
      </div>
   );
}

export default Requests;
