import type { FundManagerBatchDocumentGet } from 'types/interface';

export function folderAndFilesOfPath(
   currentPath: string,
   documents: FundManagerBatchDocumentGet['fundManagerDocuments'],
) {
   const folders: { [key: string]: string } = {};
   const files: { id: number; name: string }[] = [];

   //when inside of a path add back in the "/" which is removed from decode
   if (currentPath) {
      currentPath += '/';
   }
   documents.forEach(doc => {
      if (doc.name.startsWith(currentPath)) {
         //remove anything in filename past currentPath
         const remainingPathName = doc.name.substring(currentPath.length);
         const path = remainingPathName.split('/');
         const name = path[0];
         if (path.length > 1) {
            folders[name] = '';
         } else {
            files.push({ name, id: doc.id });
         }
      }
   });
   return { files, folders: Object.keys(folders) };
}

/**
 *
 * @param currentPath the path of the batch i.e. <root folder>/<nested folder name>
 * @param basePath the base path of the current url to be the base of the breadcrumb url
 * @returns an object with key being the name of the bread crumb, value a path to the breadcrumb
 */
export function createBreadcrumbs(
   batchName: string,
   currentPath: string,
   basePath: string,
) {
   const pathItems = currentPath ? currentPath.split('/') : [];

   const filesPath = basePath.split('/');
   //remove the batch id for the file path
   filesPath.pop();

   //the first bread crumb is Files (a list of the batches)
   const items: { [key: string]: any } = { Files: filesPath.join('/') };

   //batch name is only clickable if there are items in the path
   items[batchName] = pathItems.length ? basePath : null;

   pathItems.forEach((p, index) => {
      //add to the items each path name i.e. <nested folder>, <nested nested folder>
      //the first item should start with the folder prefix
      basePath =
         index === 0
            ? `${basePath}/folder/${encodeURIComponent(p)}`
            : `${basePath}/${encodeURIComponent(p)}`;
      //current item is not clickable
      items[p] = index === pathItems.length - 1 ? null : basePath;
   });

   return items;
}

export function formatReportingPeriod(
   reportingPeriodFormat: 'quarter' | 'month' | 'year',
   reportingPeriod: Date,
): string {
   if (!reportingPeriod || !reportingPeriodFormat) {
      return '';
   }
   reportingPeriod = new Date(reportingPeriod);
   const year = reportingPeriod.getFullYear();
   let result = `${year}`;

   if (reportingPeriodFormat.toLowerCase() === 'quarter') {
      const quarter = Math.floor((reportingPeriod.getMonth() + 3) / 3); // Calculates the quarter based on the month
      result = `Q${quarter} ${year}`;
   } else if (reportingPeriodFormat.toLocaleLowerCase() === 'month') {
      const month = reportingPeriod.toLocaleString('default', {
         month: 'short',
      });
      result = `${month} ${year}`;
   }

   return result;
}
