interface Props {
   email: string;
   investorName: string;
   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
   emailNotification: boolean;
   onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SingleInvestorRequest({
   onChange,
   email,
   investorName,
   emailNotification,
   onCheckboxChange,
}: Props) {
   return (
      <>
         <label
            htmlFor="email_address"
            className="mt-4 mb-2 block text-sm font-medium text-gray-700 text-left"
         >
            Investor's email address
         </label>
         <input
            required
            onChange={onChange}
            value={email}
            type="email"
            name="userEmail"
            id="email_address"
            autoComplete="email"
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
         />

         <label className="form-label block" htmlFor="investor-name">
            Investor Name
         </label>
         <input
            onChange={onChange}
            value={investorName}
            type="text"
            name="investorName"
            id="investor-name"
            className="form-input"
         />

         <label className="block mt-2">
            <input
               type="checkbox"
               className="mr-2"
               name="emailNotification"
               checked={emailNotification}
               onChange={onCheckboxChange}
            />
            Send Email Notification
         </label>
      </>
   );
}
