import BatchesList from '../../components/Batches/BatchesList';
import UserNavbar from '../../components/Navbar/UserNavbar';
import useGet from '../../hooks/useGet';
import { RouteComponentProps } from 'react-router-dom';
import BatchesFilter from '../../components/Batches/BatchesFilter';
import Tabs from '../../components/Tabs';
import { FundManagerBatchGet } from 'types/interface';

function isNewBatch(batch: FundManagerBatchGet) {
   return batch.requiresApproval && !batch.approvedAt && !batch.rejectedAt;
}

export default function Batches({ history, location }: RouteComponentProps) {
   const urlParams = new URLSearchParams(location.search);
   const [batches, batchesError] = useGet(`files/batches?${urlParams}`);

   const handleBatchClick = (id: number) => {
      history.push(`${location.pathname}/${id}`);
   };

   const newBatches = batches?.filter(batch => isNewBatch(batch)) || [];
   const oldBatches = batches?.filter(batch => !isNewBatch(batch)) || [];

   return (
      <div className="min-h-screen bg-gray-100">
         <UserNavbar />
         <div className="mx-4 py-20 md:py-24">
            <div className="max-w-5xl mx-auto">
               <div className="pb-1 md:pb-5 sm:flex md:items-center md:justify-between">
                  <h1 className="text-3xl font-bold leading-tight text-gray-900 text-left">
                     Files from administrator
                  </h1>
               </div>
               {batches && (
                  <Tabs
                     tabs={[
                        {
                           label: 'Pending Approval',
                           path: 'pending',
                           content: (
                              <BatchesList
                                 displayFundColumn
                                 batches={newBatches}
                                 onBatchClick={handleBatchClick}
                              />
                           ),
                        },
                        {
                           label: 'Completed',
                           path: 'completed',
                           content: (
                              <>
                                 <BatchesFilter
                                    categoriesUrl={`files/batches/categories`}
                                 />
                                 <BatchesList
                                    displayFundColumn
                                    batches={oldBatches}
                                    onBatchClick={handleBatchClick}
                                 />
                              </>
                           ),
                        },
                     ]}
                  />
               )}
            </div>
         </div>
      </div>
   );
}
