import { useHistory } from 'react-router-dom';
import Modal from './Modal';

interface Props {
   onClose: () => void;
}

export default function StaleSurveyModal({ onClose }: Props) {
   return (
      <Modal
         title="Error Updating Survey"
         onClose={onClose}
         secondaryButton={<RefreshPageButton />}
      >
         <p className="break-words max-w-full">
            This survey has been edited in another tab or by another user.
            Please refresh the page to continue editing. Changes made here will
            be lost on refresh.
         </p>
      </Modal>
   );
}

function RefreshPageButton() {
   const history = useHistory();
   return (
      <button
         className="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-800
          bg-gray-200 border border-transparent rounded-md hover:bg-blue-200 
          focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
         onClick={() => history.go(0)}
      >
         Refresh
      </button>
   );
}
