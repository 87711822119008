import { useContext, useState } from 'react';
import LoggedOutLayout from '../components/LoggedOutLayout';
import LoginForm, { MfaTokenSuccessReturn } from '../components/LoginForm';
import MfaValidate from '../components/Mfa/MfaValidate';
import MfaMethodSelect from '../components/Mfa/MfaMethodSelect';
import MfaMethodSetupSelect from '../components/Mfa/MfaMethodSetupSelect';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import type { LocationDescriptor } from 'history';
import { RedirectLocationState } from '../routes/ProtectedRoute';
import { MfaMethod, MfaMethodTypes } from 'types';
import MfaSetupSingleMethod from '../components/Mfa/MfaSetupSingleMethod';
import OfferPasskey from '../components/Mfa/Passkey/OfferPasskey';
import { passkeysSupported } from '../lib/passkey';

function Login() {
   const offerMfa = process.env.REACT_APP_OFFER_MFA;

   const configuredMfaMethods: MfaMethodTypes[] = ['email', 'app'];

   const { handleUserLogin } = useContext(UserContext);

   const history = useHistory();
   const location = useLocation<RedirectLocationState>();
   const redirect: LocationDescriptor = location.state?.from || '/';

   const [page, setPage] = useState<keyof typeof pages>('passwordLogin');
   const [activeMethods, setActiveMethods] = useState<MfaMethod[]>();
   const [selectedMethod, setSelectedMethod] = useState<MfaMethod>();
   const [selectedMethodType, setSelectedMethodType] =
      useState<MfaMethodTypes>();
   const canCancel = activeMethods && activeMethods.length > 1;
   const mfaOptional = !activeMethods; // If the array doesn't exist at all, it means we got a regular token, not MFA

   const loginSuccessfulRedirect = async () => {
      await handleUserLogin();
      history.replace(redirect);
   };

   const onMfaToken = ({ activeMethods: methods }: MfaTokenSuccessReturn) => {
      setActiveMethods(methods);
      if (methods.length === 0) {
         setPage('selectMethodtoSetup');
      } else if (methods.length === 1) {
         setSelectedMethod(methods[0]);
         setPage('validate');
      } else {
         setPage('selectMethodToValidate');
      }
   };

   const pages = {
      passwordLogin: (
         <LoginForm
            onMfaTokenSuccess={onMfaToken}
            onUserTokenSuccess={
               offerMfa
                  ? () => setPage('selectMethodtoSetup')
                  : loginSuccessfulRedirect
            }
            onPasskeySuccess={loginSuccessfulRedirect}
            onThirdPartyLoginSuccess={loginSuccessfulRedirect}
         />
      ),

      selectMethodToValidate: (
         <MfaMethodSelect
            selectableMethods={activeMethods!}
            onSuccess={method => {
               setSelectedMethod(method);
               setPage('validate');
            }}
         />
      ),

      validate: (
         <MfaValidate
            selectedMethodInfo={selectedMethod!}
            onSuccess={loginSuccessfulRedirect}
            onCancel={
               canCancel ? () => setPage('selectMethodToValidate') : null
            }
         />
      ),

      selectMethodtoSetup: (
         <MfaMethodSetupSelect
            configuredMethods={configuredMfaMethods}
            onSuccess={method => {
               setSelectedMethodType(method);
               setPage('setupMethod');
            }}
            onSkip={mfaOptional ? loginSuccessfulRedirect : null}
         />
      ),

      setupMethod: (
         <MfaSetupSingleMethod
            methodType={selectedMethodType!}
            onSuccess={
               passkeysSupported
                  ? () => setPage('offerPasskey')
                  : loginSuccessfulRedirect
            }
            onCancel={
               configuredMfaMethods.length > 1
                  ? () => setPage('selectMethodtoSetup')
                  : null
            }
            onSkip={mfaOptional ? loginSuccessfulRedirect : null}
         />
      ),

      offerPasskey: (
         <OfferPasskey
            onSuccess={loginSuccessfulRedirect}
            onCancel={loginSuccessfulRedirect}
         />
      ),
   } satisfies Record<string, JSX.Element>;

   return <LoggedOutLayout>{pages[page]}</LoggedOutLayout>;
}

export default Login;
