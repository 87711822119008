import { DocTypeGet } from 'types';

interface Props {
   docType: DocTypeGet | null;
}

const getDocTypeColorScheme = (id: number | null) => {
   //100 -- 600, 400 -- 900, 300 -- 800
   const colors = [
      'gray',
      'red',
      'amber',
      'emerald',
      'blue',
      'indigo',
      'violet',
      'pink',
   ];

   //levels are hardcoded to allow for flexibility in color scheme
   const levels = [
      { bgShade: 100, textShade: 600 },
      { bgShade: 400, textShade: 900 },
      { bgShade: 300, textShade: 800 },
   ];

   id = id ?? 0;
   const index = id % colors.length;
   const color = colors[index];
   const quotient = Math.floor(id / colors.length);

   const level = quotient < levels.length ? quotient : 0;

   const { bgShade, textShade } = levels[level];

   return {
      bgDocColor: `bg-${color}-${bgShade}`,
      textDocColor: `text-${color}-${textShade}`,
   };
};

function DocumentTypeBadge({ docType }: Props) {
   const name = docType !== null ? docType.name : 'Other';
   const id = docType !== null ? docType.id : null;

   const { bgDocColor, textDocColor } = getDocTypeColorScheme(id);

   return (
      <span
         className={`inline-flex items-center middle px-2.5 py-0.5 rounded-md text-sm font-medium ${bgDocColor} ${textDocColor}`}
      >
         <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className={`-ml-1 mr-1.5 h-4 w-4 ${textDocColor}`}
         >
            <path
               fillRule="evenodd"
               d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
               clipRule="evenodd"
            />
         </svg>
         {name}
      </span>
   );
}

export default DocumentTypeBadge;
